// src/services/WebSocketManager.js

import store from '../store'; // Adjust the path to your Redux store
import {
  receiveMessage,
  receiveCallNotification,
  receiveError,
  prependMessages,
} from '../slices/notificationSlice';
import { generateRoomName } from '../utils'; // Ensure you have this utility function

class WebSocketManager {
  constructor() {
    this.socket = null;
    this.reconnectAttempts = 0;
    this.maxReconnectAttempts = 5;
    this.reconnectDelay = 3000; // 3 seconds
    this.desiredRooms = new Set(); // All rooms the user wants to subscribe to
    this.messageQueue = [];
    this.token = null; // Store token for reconnection
    this.beforeMap = {}; // To track the 'before' timestamp per room
    this.hasMoreMap = {}; // To track if more messages are available per room
    this.messageHandlers = []; // Array of registered message handlers
  }

  /**
   * Establish WebSocket connection using the provided token.
   * @param {string} token - Authentication token.
   */
  connect(token) {
    if (
      this.socket &&
      (this.socket.readyState === WebSocket.OPEN ||
        this.socket.readyState === WebSocket.CONNECTING)
    ) {
      console.warn('WebSocket is already connected or connecting.');
      return;
    }

    // Retrieve token from localStorage if not provided
    if (!token) {
      const storedDoctorData = JSON.parse(localStorage.getItem('doctorData'));
      const storedUserData = JSON.parse(localStorage.getItem('userData'));

      if (storedDoctorData && storedDoctorData.access_token) {
        token = storedDoctorData.access_token;
      } else if (storedUserData && storedUserData.access_token) {
        token = storedUserData.access_token;
      }
    }

    if (!token) {
      console.error('Token is required to establish WebSocket connection.');
      return;
    }

    this.token = token; // Store token for reconnection

    const wsScheme = window.location.protocol === 'https:' ? 'wss' : 'ws';
    const socketUrl = `${wsScheme}://${window.location.host}/ws/chat/?token=${encodeURIComponent(
      token
    )}`;

    this.socket = new WebSocket(socketUrl);

    this.socket.onopen = () => {
      this.handleSocketOpen();
    };

    this.socket.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        console.log('Received WebSocket message:', data);

        // Handle specific message types
        switch (data.type) {
          case 'chat_message':
            this.handleChatMessage(data);
            break;
          case 'previous_messages':
            this.handlePreviousMessages(data);
            break;
          case 'call_notification':
          case 'call':  // Added case for 'call'
            this.handleCallNotification(data);
            break;
          case 'error':
            this.handleError(data);
            break;
          default:
            console.warn('Unhandled message type:', data.type);
        }
        

        // Notify all registered handlers
        this.messageHandlers.forEach((handler) => handler(data));
      } catch (error) {
        console.error('Error parsing WebSocket message:', error);
      }
    };

    this.socket.onclose = (event) => {
      console.log('WebSocket closed:', event);
      this.socket = null;
      this.isConnected = false;
      if (this.reconnectAttempts < this.maxReconnectAttempts) {
        this.reconnectAttempts++;
        const delay = this.reconnectDelay * this.reconnectAttempts;
        console.log(`Reconnecting in ${delay / 1000} seconds...`);
        setTimeout(() => this.connect(this.token), delay); // Use stored token
      } else {
        console.error(
          'Max reconnect attempts reached. Please refresh the page.'
        );
        // Dispatch a Redux action to notify the user
        store.dispatch(
          receiveError({
            message:
              'Unable to connect to the server. Please refresh the page.',
            roomName: null,
          })
        );
      }
    };

    this.socket.onerror = (error) => {
      console.error('WebSocket encountered error:', error.message);
      this.safeClose();
      // Dispatch a Redux action to notify the user
      store.dispatch(
        receiveError({
          message: 'WebSocket encountered an error. Connection closed.',
          roomName: null,
        })
      );
    };
  }

  /**
   * Handle WebSocket connection open event.
   * Subscribe to all rooms in desiredRooms.
   */
  handleSocketOpen() {
    console.log('WebSocket connected');
    this.reconnectAttempts = 0;
    this.isConnected = true;

    // Subscribe to all desired rooms
    this.desiredRooms.forEach((room) => {
      const subscribeMessage = {
        type: 'subscribe',
        room: room,
      };
      this.send(subscribeMessage);
      console.log('Subscribed to room on open:', room);

      // Fetch initial messages upon subscribing
      this.fetchPreviousMessages(room, 10);
    });

    // Send any queued messages
    while (this.messageQueue.length > 0) {
      const message = this.messageQueue.shift();
      this.send(message);
    }
  }

  /**
   * Register a message handler.
   * @param {function} handler - Function to handle incoming messages.
   */
  onMessage(handler) {
    if (typeof handler === 'function') {
      this.messageHandlers.push(handler);
    } else {
      console.error('Handler must be a function');
    }
  }

  /**
   * Unregister a message handler.
   * @param {function} handler - Function to remove from handlers.
   */
  offMessage(handler) {
    this.messageHandlers = this.messageHandlers.filter((h) => h !== handler);
  }

  /**
   * Fetch previous messages with pagination.
   * @param {string} roomName - The name of the room.
   * @param {number} limit - Number of messages to fetch.
   */
  fetchPreviousMessages(roomName, limit = 10) {
    // Check if there are more messages to fetch
    if (this.hasMoreMap[roomName] === false) {
      console.log('No more messages to fetch for room:', roomName);
      return;
    }

    const before = this.beforeMap[roomName] || null;
    const fetchMessagePayload = {
      type: 'fetch_previous_messages',
      roomName,
      limit,
    };
    if (before) {
      fetchMessagePayload.before = before;
    }
    this.send(fetchMessagePayload);
  }

  /**
   * Handle incoming chat messages.
   * Dispatches receiveMessage action to Redux store.
   * @param {object} data - The message data.
   */
  handleChatMessage(data) {
    const { roomName, sender, message, files, timestamp, id } = data;

    if (!roomName || !sender || !message || !id) {
      console.error('Invalid chat_message payload:', data);
      return;
    }

    const formattedFiles = Array.isArray(files)
      ? files.map((file) =>
          typeof file === 'string' ? { name: file } : file
        )
      : [];

    store.dispatch(
      receiveMessage({
        roomName,
        id,
        sender,
        content: message,
        files: formattedFiles,
        timestamp: timestamp || new Date().toISOString(),
        isCurrentUser: sender === this.getCurrentUserUsername(),
      })
    );
  }

  /**
   * Handle incoming previous messages.
   * Dispatches prependMessages action to Redux store.
   * @param {object} data - The previous messages data.
   */
  handlePreviousMessages(data) {
    const { roomName, messages, hasMore } = data;

    if (!roomName || !Array.isArray(messages)) {
      console.warn(
        'Received previous_messages without a valid roomName or messages array.'
      );
      return;
    }

    if (messages.length === 0) {
      console.log('No previous messages received for room:', roomName);
      this.hasMoreMap[roomName] = false;
      return;
    }

    // Reverse messages to have oldest first
    const ascendingMessages = messages.reverse();

    const formattedMessages = ascendingMessages
      .map((msg) => {
        const { id, sender, message, files, timestamp } = msg;

        if (!id || !sender || !message) {
          console.warn('Invalid message format in previous_messages:', msg);
          return null;
        }

        const formattedFiles = Array.isArray(files)
          ? files.map((file) =>
              typeof file === 'string' ? { name: file } : file
            )
          : [];

        return {
          id,
          roomName,
          sender,
          content: message,
          files: formattedFiles,
          timestamp: timestamp || new Date().toISOString(),
          isCurrentUser: sender === this.getCurrentUserUsername(),
        };
      })
      .filter((msg) => msg !== null);

    store.dispatch(
      prependMessages({
        roomName,
        messages: formattedMessages,
      })
    );

    // Set 'before' to the timestamp of the oldest message in the batch
    const earliestMessage = formattedMessages[0];
    if (earliestMessage) {
      this.beforeMap[roomName] = earliestMessage.timestamp;
    }

    // Update hasMoreMap based on hasMore flag from the server
    this.hasMoreMap[roomName] = hasMore;
  }

  /**
   * Handle incoming call notifications.
   * Dispatches receiveCallNotification action to Redux store.
   * @param {object} data - The call notification data.
   */
  handleCallNotification(data) {
    const { from, callType, roomName, ...rest } = data;

    if (!from || !callType || !roomName) {
      console.error('Invalid call notification payload:', data);
      return;
    }

    store.dispatch(
      receiveCallNotification({
        from,
        callType,
        roomName,
        ...rest,
      })
    );
  }

  /**
   * Handle error messages from the server.
   * Dispatches receiveError action to Redux store.
   * @param {object} data - The error data.
   */
  handleError(data) {
    const { message, roomName } = data;

    if (!message) {
      console.error('Invalid error payload:', data);
      return;
    }

    store.dispatch(
      receiveError({
        message,
        roomName: roomName || null,
      })
    );
  }

  /**
   * Helper method to get the current user's username.
   * Retrieves from Redux store or localStorage.
   * @returns {string|null} - The current user's username.
   */
  getCurrentUserUsername() {
    const state = store.getState();

    // Check if the user is a patient
    if (state.user?.userData?.patient?.user?.username) {
      return state.user.userData.patient.user.username;
    }

    // Check if the user is a doctor
    if (state.doctor?.doctorData?.doctor?.user?.username) {
      return state.doctor.doctorData.doctor.user.username;
    }

    // Try to get from localStorage
    const storedUserData = JSON.parse(localStorage.getItem('userData'));
    const storedDoctorData = JSON.parse(localStorage.getItem('doctorData'));

    if (storedUserData?.patient?.user?.username) {
      return storedUserData.patient.user.username;
    }

    if (storedDoctorData?.doctor?.user?.username) {
      return storedDoctorData.doctor.user.username;
    }

    console.warn('Unable to determine current user username.');
    return null;
  }

  /**
   * Subscribe to a specific chat room.
   * @param {string} roomName - The name of the room to subscribe to.
   */
  subscribeToRoom(roomName) {
    if (this.desiredRooms.has(roomName)) {
      console.warn('Already subscribed to room:', roomName);
      return;
    }

    this.desiredRooms.add(roomName);
    console.log('Added room to desiredRooms:', roomName);

    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      const subscribeMessage = {
        type: 'subscribe',
        room: roomName,
      };
      this.send(subscribeMessage);
      console.log('Subscribed to room:', roomName);

      // Fetch initial messages upon subscribing
      this.fetchPreviousMessages(roomName, 10);
    } else {
      console.warn(
        'WebSocket is not open. Subscription will be sent when connected:',
        roomName
      );
      // The room will be subscribed when the WebSocket connects (handled in handleSocketOpen)
    }
  }

  /**
   * Unsubscribe from a specific chat room.
   * @param {string} roomName - The name of the room to unsubscribe from.
   */
  unsubscribeFromRoom(roomName) {
    if (!this.desiredRooms.has(roomName)) {
      console.warn('Not subscribed to room:', roomName);
      return;
    }

    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      const unsubscribeMessage = {
        type: 'unsubscribe',
        room: roomName,
      };
      this.send(unsubscribeMessage);
      console.log('Unsubscribed from room:', roomName);
    } else {
      console.warn(
        'WebSocket is not open. Cannot unsubscribe from room:',
        roomName
      );
    }

    this.desiredRooms.delete(roomName);
    console.log('Removed room from desiredRooms:', roomName);

    // Clean up related state
    delete this.beforeMap[roomName];
    delete this.hasMoreMap[roomName];
  }

  /**
   * Send a message through the WebSocket connection.
   * If the connection isn't open, queue the message.
   * @param {object} data - The data to send.
   */
  send(data) {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(JSON.stringify(data));
    } else {
      console.warn('WebSocket is not open. Queueing message:', data);
      this.messageQueue.push(data);
    }
  }

  /**
   * Safely close the WebSocket connection.
   */
  safeClose() {
    if (this.socket && this.socket.readyState !== WebSocket.CLOSED) {
      this.socket.close();
      this.socket = null;
    }
  }

  /**
   * Close the WebSocket connection and clear internal state.
   */
  close() {
    this.safeClose();
    this.desiredRooms.clear();
    this.messageQueue = [];
    this.token = null;
    this.reconnectAttempts = 0;
    this.messageHandlers = [];
    this.beforeMap = {};
    this.hasMoreMap = {};
  }

  /**
   * Get the current WebSocket connection state.
   * @returns {number|null} - The WebSocket readyState or null if not connected.
   */
  getState() {
    return this.socket ? this.socket.readyState : null;
  }
}

// Instantiate and export a single WebSocketManager instance
const webSocketManager = new WebSocketManager();
export default webSocketManager;
