import React, { useState } from 'react'; 
import { useDispatch, useSelector } from 'react-redux';
import { bookAppointment, verifyPayment } from '../slices/appointmentSlice';
import '../CSS/AppointmentModal.css'; // Ensure this CSS file is updated as shown below

const AppointmentModal = ({ isOpen, onClose }) => {
  const [problem, setProblem] = useState('');
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false); // New state for success
  const dispatch = useDispatch();

  // Get user information from the Redux store
  const userState = useSelector((state) => state.user.userData);
  const {
    patient: {
      user: { username = '', email = '', first_name = '', last_name = '', id: userId } = {},
      country = '',
      dob = '',
      gender = '',
      phone = '',
      medical_history = [],
      medical_reports = [],
    } = {},
  } = userState || {}; // Extract user data with default values

  // Determine pricing based on country
  const isIndia = country.toLowerCase() === 'india';
  const originalPrice = isIndia ? 2000 : 60; // INR for India, USD for international
  const discountedPrice = isIndia ? 1000 : 30;
  const currency = isIndia ? 'INR' : 'USD';

  const handlePayment = async (appointmentData) => {
    try {
      setLoading(true); // Set loading to true

      // Book appointment via the backend
      const appointmentResponse = await dispatch(bookAppointment({ ...appointmentData, currency })).unwrap();

      if (appointmentResponse.error) {
        alert('Error booking appointment');
        setLoading(false);
        return;
      }

      const { razorpay_order_id, currency: responseCurrency, amount } = appointmentResponse;

      const options = {
        key: 'rzp_live_6viAxivNHxiCPI',
        amount: amount, // This should be in the smallest currency unit (paise or cents)
        currency: responseCurrency || 'INR',
        name: 'MedBridge Access',
        description: 'Appointment Booking',
        image: 'https://www.medbridgeaccess.com/static/icons/logo.webp',
        order_id: razorpay_order_id,
        prefill: {
          name: `${first_name} ${last_name}`,
          email: email,
          contact: phone,
        },
        notes: {
          address: country || "Clinic's Address",
        },
        theme: {
          color: '#840442',
        },
        handler: async function (response) {
          const paymentData = {
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_order_id: response.razorpay_order_id,
            razorpay_signature: response.razorpay_signature,
          };

          const verifyResponse = await dispatch(verifyPayment(paymentData));
          setLoading(false);

          if (verifyResponse.error) {
            alert('Payment verification failed');
          } else {
            // Set success state instead of alert and closing
            setIsSuccess(true);
          }
        },
      };

      const rzp1 = new window.Razorpay(options); // Use Razorpay from window object

      // Handle payment failure
      rzp1.on('payment.failed', function (response) {
        alert(`Payment Failed: ${response.error.description}`);
        alert(`Error Code: ${response.error.code}`);
        alert(`Order ID: ${response.error.metadata.order_id}`);
        alert(`Payment ID: ${response.error.metadata.payment_id}`);
        setLoading(false); // Set loading to false
      });

      // Open the Razorpay modal for the user to complete the payment
      rzp1.open();
    } catch (error) {
      console.error('Error during payment:', error);
      alert('An error occurred while processing the payment. Please try again.');
      setLoading(false); // Set loading to false
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!problem) {
      alert('Please describe your medical issue.');
      return;
    }

    // Prepare the appointment data with user information
    const appointmentData = {
      problem_description: problem,
      patient: userId, // Assuming logged-in patient ID
      currency, // Pass currency to backend
      amount: discountedPrice * (currency === 'INR' ? 100 : 100), // Convert to paise or cents if needed
    };

    // Call the payment handler
    await handlePayment(appointmentData);
  };

  if (!isOpen) return null;

  return (
    <div className="appointment-modal-overlay">
      <div className="appointment-modal">
        <button className="appointment-close-button" onClick={onClose}>✕</button>
        <div className="appointment-modal-content">
          {!isSuccess ? (
            <div className="appointment-form-container">
              <h2>Book Your Appointment Now!</h2>
              <p>We are here to help. Describe your issue, and we'll take care of the rest.</p>
              <form onSubmit={handleSubmit}>
                <textarea
                  value={problem}
                  onChange={(e) => setProblem(e.target.value)}
                  placeholder="Describe your medical issue"
                  required
                />
                <div className="pricing-details">
                  <table>
                    <tbody>
                      <tr>
                        <td className="original-price">Original Price</td>
                        <td className="original-price-value">
                          {isIndia ? `₹${originalPrice}` : `$${originalPrice}`}
                        </td>
                      </tr>
                      <tr>
                        <td className="discount">Discount (50%)</td>
                        <td className="discount-value">
                          - {isIndia ? `₹${originalPrice - discountedPrice}` : `$${originalPrice - discountedPrice}`}
                        </td>
                      </tr>
                      <tr>
                        <td><strong>Final Price</strong></td>
                        <td><strong className="final-price">
                          {isIndia ? `₹${discountedPrice}` : `$${discountedPrice}`}
                        </strong></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <button type="submit" disabled={loading}>
                  {loading ? 'Processing...' : 'Book Now & Pay'}
                </button>
              </form>
            </div>
          ) : (
            <div className="appointment-thankyou-container">
              <h2>Thank You for Booking with Us!</h2>
              <p>
                We're thrilled to assist you on your health journey. Our team at MedBridge Access will reach out to you
                with the details of your appointment within the next 24 hours. If you have any further questions or need
                immediate assistance, feel free to contact us. Wishing you good health and a speedy recovery!
              </p>
              <button className="appointment-close-button" onClick={onClose}>
                ✕
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AppointmentModal;
