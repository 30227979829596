// ChatWindow.js
import React, { useEffect, useState, useRef, useCallback , useMemo} from 'react';
import { VariableSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { useDispatch, useSelector } from "react-redux";
import webSocketManager from "../services/WebSocketManager"; // Adjust the path as needed
import {  receiveMessage,
    prependMessages,
    receiveError, } from "../slices/notificationSlice"; // Adjust the path as needed
import styled from "styled-components";
import MessageInput from "./MessageInput";
import VideoCall from "./VideoCall";
import { FiPhone, FiVideo, FiPhoneOff, FiArrowLeft } from "react-icons/fi"; // Using react-icons for better scalability

const ChatWindowContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;

  @media (max-width: 768px) {
    height: 100%;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 25px;
  border-bottom: 1px solid #ddd;
  background-color: #f7f7f7;

  @media (max-width: 768px) {
    padding: 10px 15px;
    flex-direction: row;
  }
`;

const UserInfo = styled.div`
  display: flex;
  align-items: center;
`;

const UserName = styled.div`
  font-weight: 600;
  font-size: 1.2rem;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const BackButton = styled.button`
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  margin-right: 10px;

  @media (max-width: 768px) {
    display: block;
  }
`;
const DateSeparator = styled.div`
  text-align: center;
  margin: 10px 0;
  color: #888;
  font-size: 14px;
`;


const CallButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;

  @media (max-width: 768px) {
    gap: 10px;
  }
`;

const IconButton = styled.button`
  background: ${(props) => (props.end ? "#e74c3c" : "#3498db")};
  border: none;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;

  &:hover {
    background: ${(props) => (props.end ? "#c0392b" : "#2980b9")};
  }

  @media (max-width: 768px) {
    padding: 8px;
    font-size: 1rem;
  }
`;

const MessagesContainer = styled.div`
  flex: 1;
  padding: 15px;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const MessageWrapper = styled.div`
  display: flex;
  justify-content: ${(props) =>
    props.$isCurrentUser ? "flex-end" : "flex-start"};
`;

const Loader = styled.div`
  text-align: center;
  padding: 10px;
  color: #888;
`;

const EndOfMessages = styled.div`
  text-align: center;
  padding: 10px;
  color: #888;
`;

const MessageBubble = styled.div`
  background-color: ${(props) =>
    props.$isCurrentUser ? "#DCF8C6" : "#E8E8E8"};
  border-radius: 10px;
  padding: 10px 15px;
  max-width: 70%;
  word-wrap: break-word;
  font-size: 1rem;

  @media (max-width: 768px) {
    max-width: 80%;
    padding: 8px 12px;
    font-size: 0.9rem;
  }
`;





const ChatWindow = ({ selectedUser, roomName, currentUser, onBack }) => {
  const dispatch = useDispatch();

  // **Messages from Redux state**
  const messages = useSelector(
    (state) => state.notifications.messages[roomName] || []
  );

  // **State variables**
  const [callType, setCallType] = useState(null);
  const [isVideoCallActive, setIsVideoCallActive] = useState(false);
  const [isInitiator, setIsInitiator] = useState(false);
  const [isUserInCall, setIsUserInCall] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  // **Refs**
  const videoCallRef = useRef(null);
  const ringtoneRef = useRef(null);
  const dialerRef = useRef(null);
  const listRef = useRef(null);

  // **Constants**
  const ITEM_SIZE = 80; // Height of each message item in pixels

  // **Refs to track previous state**
  const previousMessagesLengthRef = useRef(0);
  const isInitialLoadRef = useRef(true);
  const scrollHeightBeforeRef = useRef(0);
  const scrollTopBeforeRef = useRef(0);

  // **Utility Functions to Get Current User Info**
  const getCurrentUserUsername = useCallback(() => {
    if (currentUser.user && currentUser.user.username) {
      return currentUser.user.username;
    } else if (
      currentUser.patient &&
      currentUser.patient.user &&
      currentUser.patient.user.username
    ) {
      return currentUser.patient.user.username;
    } else if (
      currentUser.doctor &&
      currentUser.doctor.user &&
      currentUser.doctor.user.username
    ) {
      return currentUser.doctor.user.username;
    } else {
      return null;
    }
  }, [currentUser]);

  const getCurrentUserFullName = useCallback(() => {
    if (currentUser.user && currentUser.user.first_name) {
      return `${currentUser.user.first_name} ${currentUser.user.last_name}`;
    } else if (
      currentUser.patient &&
      currentUser.patient.user &&
      currentUser.patient.user.first_name
    ) {
      return `${currentUser.patient.user.first_name} ${currentUser.patient.user.last_name}`;
    } else if (
      currentUser.doctor &&
      currentUser.doctor.user &&
      currentUser.doctor.user.first_name
    ) {
      return `${currentUser.doctor.user.first_name} ${currentUser.doctor.user.last_name}`;
    } else {
      return 'Unknown User';
    }
  }, [currentUser]);

  const getCurrentUserType = useCallback(() => {
    if (currentUser.user_type) {
      return currentUser.user_type;
    } else if (currentUser.patient && currentUser.patient.user_type) {
      return currentUser.patient.user_type;
    } else if (currentUser.doctor && currentUser.doctor.user_type) {
      return currentUser.doctor.user_type;
    } else {
      return 'unknown';
    }
  }, [currentUser]);

  // **Handle end call**
  const handleEndCall = useCallback(() => {
    setCallType(null);
    setIsVideoCallActive(false);
    setIsInitiator(false);
    setIsUserInCall(false);

    if (dialerRef.current) {
      dialerRef.current.pause();
      dialerRef.current.currentTime = 0;
    }
  }, []);

  // **End call**
  const endCall = useCallback(() => {
    webSocketManager.send({
      type: 'end-call',
      to: selectedUser.user.username,
      roomName,
    });
    handleEndCall();
  }, [selectedUser.user.username, roomName, handleEndCall]);

  // **Start a call**
  const startCall = useCallback(
    (type) => {
      if (isUserInCall) {
        alert('You are already in a call.');
        return;
      }

      setCallType(type);
      setIsInitiator(true);
      setIsVideoCallActive(true);
      setIsUserInCall(true);

      // **Play Dialer Tune**
      if (dialerRef.current) {
        dialerRef.current
          .play()
          .catch((error) => console.error('Dialer play error:', error));
      }

      // **Send call initiation via WebSocketManager**
      webSocketManager.send({
        type: 'call',
        to: selectedUser.user.username,
        from: getCurrentUserUsername(),
        call_type: type,
        roomName,
      });
    },
    [isUserInCall, selectedUser.user.username, roomName, getCurrentUserUsername]
  );
  useEffect(() => {
    // Reset state variables when roomName changes
    setHasMore(true);
    setIsLoading(false);
    previousMessagesLengthRef.current = 0;
    isInitialLoadRef.current = true;
    scrollHeightBeforeRef.current = 0;
    scrollTopBeforeRef.current = 0;
  }, [roomName]);
  

  // **Handle sending a message**
  const sendMessage = useCallback(
    ({ message, files }) => {
      if (message) {
        // **Prepare the message payload**
        const payload = {
          type: 'chat_message',
          message,
          files: files.map((file) => ({ name: file.name })), // Ensure consistent structure
          to: selectedUser.user.username,
          senderType: getCurrentUserType(),
          receiverType: selectedUser.user_type,
          roomName,
        };

        // **Send message via WebSocketManager**
        webSocketManager.send(payload);
      }
    },
    [
      selectedUser.user.username,
      selectedUser.user_type,
      roomName,
      getCurrentUserType,
    ]
  );

  // **Scroll to bottom function**
  const scrollToBottom = () => {
    if (listRef.current) {
      listRef.current.scrollToItem(processedMessages.length - 1, 'end');
    }
  };

  // **Check if user is near the bottom**
  const isAtBottom = () => {
    if (!listRef.current) return false;
    const list = listRef.current;
    const scrollOffset = list.scrollOffset || 0;
    const { height } = list.props;
    const totalHeight = processedMessages.length * ITEM_SIZE;
    return scrollOffset + height >= totalHeight - ITEM_SIZE;
  };

  // **Fetch initial messages**
  const fetchInitialMessages = useCallback(() => {
    setIsLoading(true);
    webSocketManager.fetchPreviousMessages(roomName, 10); // Fetch the latest 10 messages
  }, [roomName]);

  // **Load more messages when scrolling to top**
  const loadMoreMessages = useCallback(() => {
    if (isLoading || !hasMore) return;
    setIsLoading(true);

    // Save the current scroll height and scroll position
    if (listRef.current) {
      const list = listRef.current;
      scrollHeightBeforeRef.current = list._outerRef.scrollHeight;
      scrollTopBeforeRef.current = list._outerRef.scrollTop;
    }

    webSocketManager.fetchPreviousMessages(roomName, 10); // Fetch next 10 messages
  }, [isLoading, hasMore, roomName]);

  // **Handle Errors from Redux Store**
  const errorMessages = useSelector((state) =>
    state.notifications.errors.filter((err) => err.roomName === roomName)
  );

  const handleError = useCallback(
    (message) => {
      if (message.includes('call')) {
        alert(message);
        setIsUserInCall(false);
      } else {
        console.error('WebSocket Error:', message);
      }
    },
    [setIsUserInCall]
  );

  useEffect(() => {
    if (errorMessages.length > 0) {
      errorMessages.forEach((error) => {
        handleError(error.message);
        // Optionally, dispatch an action to remove the error from the store
        // dispatch(clearError(error.id));
      });
    }
  }, [errorMessages, handleError]);

  // **Initialize audio and subscribe to room on mount**
  useEffect(() => {
    // **Subscribe to the room**
    webSocketManager.subscribeToRoom(roomName);

    // **Initialize audio**
    ringtoneRef.current = new Audio('/static/audio/ringtone.mp3');
    ringtoneRef.current.loop = true;

    // **Initialize Dialer Tune**
    dialerRef.current = new Audio('/static/audio/dialer.mp3');
    dialerRef.current.loop = true;

    // **Fetch initial messages**
    fetchInitialMessages();

    // **Cleanup on unmount**
    return () => {
      // **Unsubscribe from the room**
      webSocketManager.unsubscribeFromRoom(roomName);

      // **Clean up audio**
      if (ringtoneRef.current) {
        ringtoneRef.current.pause();
        ringtoneRef.current = null;
      }
      if (dialerRef.current) {
        dialerRef.current.pause();
        dialerRef.current = null;
      }
    };
  }, [roomName, fetchInitialMessages]);

  // **Handle incoming WebSocket messages**
  useEffect(() => {
    const handleMessage = (data) => {
      if (data.roomName !== roomName) return;

      if (data.type === 'chat_message') {
        if (isAtBottom() && !isLoading) {
          scrollToBottom();
        }
      } else if (data.type === 'previous_messages') {
        setIsLoading(false);
        if (data.hasMore === false) {
          setHasMore(false);
        }
      } else if (data.type === 'error') {
        setIsLoading(false);
      }
    };

    webSocketManager.onMessage(handleMessage);

    return () => {
      webSocketManager.offMessage(handleMessage);
    };
  }, [roomName, isAtBottom, scrollToBottom, isLoading]);

  // **Adjust scroll position after new messages are loaded**
  useEffect(() => {
    const messagesDiff = messages.length - previousMessagesLengthRef.current;

    if (isInitialLoadRef.current && messages.length > 0) {
      // **Initial load: scroll to bottom**
      scrollToBottom();
      isInitialLoadRef.current = false;
      setIsLoading(false);
    } else if (isLoading && messagesDiff > 0 && listRef.current) {
      // **Loading more messages (older messages prepended)**
      const list = listRef.current;
      const scrollHeightAfter = list._outerRef.scrollHeight;
      const scrollHeightBefore = scrollHeightBeforeRef.current || 0;
      const scrollTopBefore = scrollTopBeforeRef.current || 0;

      const scrollDelta = scrollHeightAfter - scrollHeightBefore;
      list._outerRef.scrollTop = scrollTopBefore + scrollDelta;

      // Clear saved scroll heights
      scrollHeightBeforeRef.current = 0;
      scrollTopBeforeRef.current = 0;

      setIsLoading(false);
    }

    previousMessagesLengthRef.current = messages.length;
  }, [messages, isLoading, scrollToBottom]);

  // **Process messages to include date separators**
  const processedMessages = useMemo(() => {
    const items = [];
    let lastDate = null;

    messages.forEach((msg) => {
      const msgDate = new Date(msg.timestamp).toDateString();
      if (msgDate !== lastDate) {
        items.push({ type: 'date', date: msgDate });
        lastDate = msgDate;
      }
      items.push({ type: 'message', message: msg });
    });

    return items;
  }, [messages]);

  // **Get item size function for VariableSizeList**
  const getItemSize = (index) => {
    const item = processedMessages[index];
    if (item.type === 'date') {
      return 40; // Height for date separator
    } else {
      return 80; // Height for message item
    }
  };

  // **Render a single message or date separator**
  const Row = ({ index, style }) => {
    const item = processedMessages[index];

    if (item.type === 'date') {
      return (
        <div style={style} key={`date-${item.date}`}>
          <DateSeparator>{item.date}</DateSeparator>
        </div>
      );
    } else if (item.type === 'message') {
      const msg = item.message;
      return (
        <div style={style} key={msg.id}>
          <MessageWrapper $isCurrentUser={msg.isCurrentUser}>
            <MessageBubble $isCurrentUser={msg.isCurrentUser}>
              {msg.files?.length > 0 && (
                <div>
                  <strong>Files:</strong>
                  <ul>
                    {msg.files.map((file, fileIndex) => (
                      <li key={fileIndex}>{file.name || file}</li>
                    ))}
                  </ul>
                </div>
              )}
              <div>{msg.content}</div>
              <div>
                <small>
                  {new Date(msg.timestamp).toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit',
                  })}
                </small>
              </div>
            </MessageBubble>
          </MessageWrapper>
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <ChatWindowContainer>
      <Header>
        <UserInfo>
          {onBack && (
            <BackButton onClick={onBack} aria-label="Back to Previous View">
              <FiArrowLeft />
            </BackButton>
          )}
          <UserName>
            {selectedUser.user.first_name} {selectedUser.user.last_name}
          </UserName>
        </UserInfo>
        <CallButtons>
          {!isVideoCallActive && !isUserInCall && (
            <>
              <IconButton
                onClick={() => startCall('audio')}
                aria-label="Start Audio Call"
              >
                <FiPhone />
              </IconButton>
              <IconButton
                onClick={() => startCall('video')}
                aria-label="Start Video Call"
              >
                <FiVideo />
              </IconButton>
            </>
          )}
          {(isVideoCallActive || isUserInCall) && (
            <IconButton end onClick={endCall} aria-label="End Call">
              <FiPhoneOff />
            </IconButton>
          )}
        </CallButtons>
      </Header>
      <MessagesContainer>
        <AutoSizer>
          {({ height, width }) => (
            <List
              height={height}
              itemCount={processedMessages.length}
              itemSize={getItemSize}
              width={width}
              ref={listRef}
              onItemsRendered={({ visibleStartIndex }) => {
                if (visibleStartIndex === 0 && hasMore && !isLoading) {
                  loadMoreMessages();
                }
              }}
              overscanCount={5}
              itemData={processedMessages}
            >
              {Row}
            </List>
          )}
        </AutoSizer>
        {isLoading && <Loader>Loading...</Loader>}
        {!hasMore && <EndOfMessages>No more messages</EndOfMessages>}
      </MessagesContainer>
      <MessageInput onSendMessage={sendMessage} />
      {isVideoCallActive && (
        <VideoCall
          ref={videoCallRef}
          socket={webSocketManager}
          selectedUser={selectedUser}
          currentUser={currentUser}
          callType={callType}
          onEndCall={handleEndCall}
          isInitiator={isInitiator}
          currentUserUsername={getCurrentUserUsername()}
        />
      )}
    </ChatWindowContainer>
  );
};

export default ChatWindow;