// ChatApp.js

import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import ChatList from '../Components/ChatList';
import ChatWindow from '../Components/ChatWindow';
import { useLocation } from 'react-router-dom';
import { generateRoomName } from '../utils'; // Import utility function
import webSocketManager from '../services/WebSocketManager';

const ChatAppContainer = styled.div`
  display: flex;
  height: 100vh;
  overflow: hidden;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const ChatListContainer = styled.div`
  width: 30%;
  border-right: 1px solid #ddd;
  overflow-y: auto;

  @media (max-width: 768px) {
    width: 100%;
    display: ${({ isMobileView }) => (isMobileView ? 'block' : 'none')};
  }
`;

const ChatWindowContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  position: relative;

  @media (max-width: 768px) {
    width: 100%;
    display: ${({ isMobileView }) => (isMobileView ? 'block' : 'none')};
  }
`;

const ChatApp = () => {
  const dispatch = useDispatch();

  // **Retrieve userData and doctorData from Redux store or localStorage**
  const userDataFromRedux = useSelector((state) => state.user.userData);
  const doctorDataFromRedux = useSelector((state) => state.doctor.doctorData);

  const [userData, setUserData] = useState(() => {
    return (
      userDataFromRedux ||
      JSON.parse(localStorage.getItem('userData')) ||
      null
    );
  });

  const [doctorData, setDoctorData] = useState(() => {
    return (
      doctorDataFromRedux ||
      JSON.parse(localStorage.getItem('doctorData')) ||
      null
    );
  });

  useEffect(() => {
    // Update userData and doctorData when Redux store changes
    if (!userDataFromRedux) {
      const storedUserData = JSON.parse(localStorage.getItem('userData'));
      if (storedUserData) {
        setUserData(storedUserData);
      }
    } else {
      setUserData(userDataFromRedux);
    }

    if (!doctorDataFromRedux) {
      const storedDoctorData = JSON.parse(localStorage.getItem('doctorData'));
      if (storedDoctorData) {
        setDoctorData(storedDoctorData);
      }
    } else {
      setDoctorData(doctorDataFromRedux);
    }
  }, [userDataFromRedux, doctorDataFromRedux]);

  // **Ensure currentUser has the expected structure**
  const currentUser = useMemo(() => {
    if (doctorData && doctorData.doctor) {
      return {
        ...doctorData,
        user_type: 'doctor',
      };
    } else if (userData && userData.patient) {
      return {
        ...userData,
        user_type: 'patient',
      };
    } else {
      return null;
    }
  }, [doctorData, userData]);

  // **Determine current user's ID based on user type**
  const currentUserId =
    currentUser?.doctor?.user?.id ||
    currentUser?.patient?.user?.id ||
    currentUser?.user?.id ||
    currentUser?.id;

  // **Retrieve the selected user from localStorage or location state**
  const location = useLocation();
  const [selectedUser, setSelectedUser] = useState(() => {
    const storedUser = localStorage.getItem('selectedUser');
    if (storedUser) {
      try {
        return JSON.parse(storedUser);
      } catch (error) {
        console.error('Error parsing storedUser:', error);
        return null;
      }
    } else if (location.state && location.state.selectedUser) {
      return location.state.selectedUser;
    } else {
      return null;
    }
  });

  // Update selectedUser state if location.state changes
  useEffect(() => {
    if (location.state && location.state.selectedUser) {
      setSelectedUser(location.state.selectedUser);
      localStorage.setItem(
        'selectedUser',
        JSON.stringify(location.state.selectedUser)
      );
    }
  }, [location.state]);

  useEffect(() => {
    if (!currentUser) {
      console.log('User not logged in.');
    } else {
      console.log('currentUser:', currentUser);
    }

    const checkMobile = () => {
      if (window.innerWidth <= 768) {
        setIsMobileView(true);
      } else {
        setIsMobileView(false);
      }
    };

    checkMobile();

    window.addEventListener('resize', checkMobile);

    return () => window.removeEventListener('resize', checkMobile);
  }, [currentUser]);

  // **Responsive Design State**
  const [isMobileView, setIsMobileView] = useState(false);

  // **Subscribe to room when selectedUser changes**
  // *Note:* Subscription is handled by WebSocketInitializer in App.js

  const generateRoom = (user1Id, user2Id) => {
    return generateRoomName(user1Id, user2Id); // Use centralized utility
  };

  if (!currentUser) {
    return <div>Please log in to view your chats.</div>;
  }

  // Handler for closing the chat window
  const handleCloseChat = () => {
    setSelectedUser(null);
    localStorage.removeItem('selectedUser');
  };

  const showChatList = isMobileView ? !selectedUser : true;
  const showChatWindow = isMobileView ? selectedUser : true;

  const handleSetSelectedUser = (user) => {
    setSelectedUser(user);
    if (user) {
      localStorage.setItem('selectedUser', JSON.stringify(user));
    } else {
      localStorage.removeItem('selectedUser');
    }
  };

  return (
    <ChatAppContainer>
      <ChatListContainer isMobileView={showChatList}>
        <ChatList setSelectedUser={handleSetSelectedUser} />
      </ChatListContainer>

      <ChatWindowContainer isMobileView={showChatWindow}>
        {isMobileView && selectedUser && (
          <ChatWindow
            selectedUser={selectedUser}
            roomName={generateRoom(currentUserId, selectedUser.user.id)}
            currentUser={currentUser}
            onBack={handleCloseChat} // Using onBack prop
          />
        )}
        {!isMobileView && selectedUser && (
          <ChatWindow
            selectedUser={selectedUser}
            roomName={generateRoom(currentUserId, selectedUser.user.id)}
            currentUser={currentUser}
            onBack={handleCloseChat} // Using onBack prop
          />
        )}
        {!selectedUser && !isMobileView && (
          <div style={{ padding: '20px' }}>
            Select a chat to start messaging.
          </div>
        )}
      </ChatWindowContainer>
    </ChatAppContainer>
  );
};

export default ChatApp;