// AdminNavbar.jsx

import React from 'react';
import { NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { adminLogout } from '../slices/adminSlice'; // Adjust the import path
import '../CSS/AdminDashboard.css'; // Import the CSS file with your styles

const AdminNavbar = () => {
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(adminLogout());
    // Optionally redirect to login page
    window.location.href = '/admin/login';
  };

  return (
    <div className="appointment-dashboard-sidebar">
      <div className="appointment-dashboard-logo">Admin Dashboard</div>
      <div className="appointment-dashboard-nav-items">
        <NavLink
          to="/admin/admin-dashboard"
          className="appointment-dashboard-nav-item"
          activeClassName="active"
        >
          <span>Appointments</span>
        </NavLink>
        <NavLink
          to="/admin/patients"
          className="appointment-dashboard-nav-item"
          activeClassName="active"
        >
          <span>Patients</span>
        </NavLink>
        <NavLink
          to="/admin/doctors"
          className="appointment-dashboard-nav-item"
          activeClassName="active"
        >
          <span>Doctors</span>
        </NavLink>
      </div>
      <button onClick={handleLogout} className="appointment-dashboard-nav-item">
        <span>Logout</span>
      </button>
    </div>
  );
};

export default AdminNavbar;
