import React, { useState, useEffect } from 'react';
import { ChevronDown } from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPatients, adminLogout } from '../slices/adminSlice';
import { useNavigate } from 'react-router-dom';
import AdminNavbar from '../Components/AdminNavbar';
import '../CSS/AdminDashboard.css';

const AdminPatientDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const patients = useSelector((state) => state.admin.patients);
  const patientStatus = useSelector((state) => state.admin.patientStatus);
  const error = useSelector((state) => state.admin.patientError);
  const adminToken = useSelector((state) => state.admin.adminToken);

  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    if (!adminToken) {
      navigate('/admin/login');
    }
  }, [adminToken, navigate]);

  useEffect(() => {
    if (patientStatus === 'idle') {
      dispatch(fetchPatients());
    }
  }, [patientStatus, dispatch]);

  const handleLogout = () => {
    dispatch(adminLogout());
    navigate('/admin/login');
  };

  const filteredPatients = patients.filter((patient) => {
    const fullName = `${patient.user.first_name} ${patient.user.last_name}`.toLowerCase();
    const email = patient.user.email.toLowerCase();
    const phone = patient.phone.toLowerCase();
    const search = searchTerm.toLowerCase();

    return (
      fullName.includes(search) ||
      email.includes(search) ||
      phone.includes(search)
    );
  });

  return (
    <div className="admin-dashboard-container">
      <AdminNavbar />
      <div className="admin-dashboard-content">
        <div className="appointment-dashboard-header">
          <h2>Patients</h2>
          <div className="patient-search">
            <input
              type="text"
              placeholder="Search by name, email, or phone"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="patient-search-input"
            />
          </div>
        </div>

        <div className="appointment-dashboard-appointments-list">
          <div className="patient-dashboard-header">
            <div>ID</div>
            <div>Name</div>
            <div>Email</div>
            <div>Phone</div>
            <div>Country</div>
            <div>Date of Birth</div>
            <div>Gender</div>
            <div>Details</div>
          </div>

          {patientStatus === 'loading' && <p>Loading patients...</p>}
          {patientStatus === 'failed' && <p>Error: {error}</p>}
          {patientStatus === 'succeeded' &&
            filteredPatients.map((patient) => (
              <React.Fragment key={patient.id}>
                <div className="patient-dashboard-item">
                  <div>{patient.id}</div>
                  <div>
                    {patient.user.first_name} {patient.user.last_name}
                  </div>
                  <div>{patient.user.email}</div>
                  <div>{patient.phone}</div>
                  <div>{patient.country}</div>
                  <div>{patient.dob}</div>
                  <div>{patient.gender}</div>
                  <button
                    className="appointment-dashboard-action-button"
                    onClick={() => {
                      // Handle showing patient details
                    }}
                    aria-label="View Details"
                  >
                    <ChevronDown size={18} />
                  </button>
                </div>
                {/* Add details section if needed */}
              </React.Fragment>
            ))}
        </div>
      </div>
    </div>
  );
};

export default AdminPatientDashboard;
