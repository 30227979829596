// src/Components/ScrollToTop.js

import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToTop() {
  const { pathname, hash } = useLocation();

  useLayoutEffect(() => {
    console.log('ScrollToTop triggered');
    if (hash) {
      // Attempt to scroll to the element with the ID matching the hash
      const elementId = hash.replace('#', '');
      const element = document.getElementById(elementId);
      if (element) {
        console.log(`Scrolling to element with ID: ${elementId}`);
        element.scrollIntoView({ behavior: 'smooth' });
      } else {
        console.warn(`Element with ID "${elementId}" not found. Scrolling to top instead.`);
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      }
    } else {
      // No hash present, scroll to the top of the page
      console.log('No hash present. Scrolling to top.');
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  }, [pathname, hash]); // Depend on both pathname and hash

  return null; // This component doesn't render anything
}

export default ScrollToTop;
