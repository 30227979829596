import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout } from '../slices/userSlice';
import '../CSS/DoctorSidebar.css';

const UserSidebar = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logout());
    navigate('/bookappointment');
  };

  // Function to determine class names for NavLink
  const getNavLinkClass = ({ isActive }) => 
    isActive ? 'nav-item nav-side-active' : 'nav-item';

  return (
    <div className={`sidebar ${isOpen ? 'open' : ''}`}>
      <button className="close-sidebar" onClick={onClose}>&times;</button>
      <div className='logo-box'>
        <img src='/static/icons/logo.webp' alt='Medbridge-access-logo' className="Medbridge-access-logo" />
      </div>

      <nav className="nav-items">
        <NavLink to="/userdashboard" className={getNavLinkClass} onClick={onClose}>
          <i className="fa-solid fa-house"></i> Dashboard
        </NavLink>
        <NavLink to="/personal-details" className={getNavLinkClass} onClick={onClose}>
          <i className="fa-regular fa-user"></i> User
        </NavLink>
        <NavLink to="/chat" className={getNavLinkClass} onClick={onClose}>
          <i className="fa-regular fa-message"></i> Messages
        </NavLink>
        <button onClick={handleLogout} className="nav-item logout-button">
          <i className="fa-solid fa-arrow-right-from-bracket"></i> Logout
        </button>
      </nav>
    </div>
  );
};

export default UserSidebar;
