// DoctorChatPage.js
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import DoctorChatList from '../Components/DoctorChatList';
import PatientProfile from '../Components/PatientProfile';
import ChatWindow from '../Components/ChatWindow';
import { fetchPatientDetails, clearPatientDetails } from '../slices/patientSlice';
import { FiArrowLeft } from 'react-icons/fi'; // For mobile back button

const ChatAppContainer = styled.div`
  display: flex;
  height: 100vh;
  overflow: hidden;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const ChatListContainer = styled.div`
  width: 25%;
  border-right: 1px solid #ddd;
  overflow-y: auto;

  @media (max-width: 768px) {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid #ddd;
    display: ${({ show }) => (show ? 'block' : 'none')};
  }
`;

const PatientProfileContainer = styled.div`
  width: 25%;
  border-right: 1px solid #ddd;
  overflow-y: auto;

  @media (max-width: 768px) {
    width: 100%;
    display: ${({ show }) => (show ? 'block' : 'none')};
  }
`;

const ChatWindowContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  position: relative;

  @media (max-width: 768px) {
    width: 100%;
    display: ${({ show }) => (show ? 'block' : 'none')};
  }
`;

const MobileHeader = styled.div`
  display: none;
  align-items: center;
  padding: 10px 15px;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ddd;

  @media (max-width: 768px) {
    display: flex;
  }
`;

const ToggleButton = styled.button`
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  margin-right: 10px;

  @media (max-width: 768px) {
    display: block;
  }
`;

const ContentPlaceholder = styled.div`
  padding: 20px;
  text-align: center;
  color: #666;
`;

const DoctorChatPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [isChatStarted, setIsChatStarted] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const doctorData = useSelector((state) => state.doctor.doctorData);
  const { details: patientDetails, loading, error } = useSelector((state) => state.patient);

  useEffect(() => {
    // Update isMobile state on window resize
    const handleResize = () => {
      const mobile = window.innerWidth <= 768;
      setIsMobile(mobile);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Fetch patient details when a patient is selected
  useEffect(() => {
    if (selectedPatient) {
      dispatch(fetchPatientDetails(selectedPatient.id));
    } else {
      dispatch(clearPatientDetails());
    }
  }, [dispatch, selectedPatient]);

  // Flatten the doctor data to match the expected structure
  const currentUser = doctorData?.doctor
    ? {
        ...doctorData.doctor,
        user_type: 'doctor',
        access_token: doctorData.access_token,
        refresh_token: doctorData.refresh_token,
      }
    : null;

  // Generate room name for chat
  const generateRoomName = (user1Id, user2Id) => {
    return [user1Id, user2Id].sort().join('_');
  };

  // Handlers for initiating and ending chat
  const handleStartChat = () => {
    setIsChatStarted(true);
  };

  const handleEndChat = () => {
    setIsChatStarted(false);
  };

  // Handlers for mobile navigation
  const navigateToChatList = () => {
    setIsChatStarted(false);
    setSelectedPatient(null);
  };

  const navigateToPatientProfile = () => {
    setIsChatStarted(false);
  };

  if (!doctorData) {
    return <ContentPlaceholder>Loading doctor data...</ContentPlaceholder>;
  }

  return (
    <ChatAppContainer>
      {/* Chat List Section */}
      <ChatListContainer show={!selectedPatient || (!isChatStarted && isMobile)}>
        <DoctorChatList setSelectedPatient={setSelectedPatient} />
      </ChatListContainer>

      {/* Patient Profile Section */}
      {selectedPatient && !isChatStarted && (
        <PatientProfileContainer show={selectedPatient && !isChatStarted}>
          {isMobile && (
            <MobileHeader>
              <ToggleButton onClick={navigateToChatList} aria-label="Back to Chat List">
                <FiArrowLeft />
              </ToggleButton>
              <h3>Patient Profile</h3>
            </MobileHeader>
          )}
          {loading ? (
            <ContentPlaceholder>Loading patient details...</ContentPlaceholder>
          ) : error ? (
            <ContentPlaceholder>Error loading patient details: {error.message}</ContentPlaceholder>
          ) : (
            <PatientProfile
              selectedUser={{ ...patientDetails, user_type: 'patient' }}
              onStartChat={handleStartChat}
            />
          )}
        </PatientProfileContainer>
      )}

      {/* Chat Window Section */}
      {selectedPatient && isChatStarted && (
        <ChatWindowContainer show={selectedPatient && isChatStarted}>
          {isMobile && (
            <MobileHeader>
              <ToggleButton onClick={navigateToPatientProfile} aria-label="Back to Profile">
                <FiArrowLeft />
              </ToggleButton>
              <h3>Chat</h3>
            </MobileHeader>
          )}
          <ChatWindow
            selectedUser={{ ...patientDetails, user_type: 'patient' }}
            roomName={generateRoomName(doctorData.doctor.user.id, selectedPatient.user.id)}
            currentUser={currentUser}
            onEndChat={handleEndChat}
          />
        </ChatWindowContainer>
      )}
    </ChatAppContainer>
  );
};

export default DoctorChatPage;
