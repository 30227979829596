// src/NotificationPermission.js

import { useEffect } from 'react';

const NotificationPermission = () => {
  useEffect(() => {
    // Check if the Notification API is available
    if (typeof Notification !== 'undefined') {
      if (Notification.permission !== 'granted') {
        Notification.requestPermission().catch((err) => {
          console.error('Notification permission request failed:', err);
        });
      }
    } else {
      console.warn('Notification API is not supported in this environment.');
    }
  }, []);

  return null; // This component doesn't render anything
};

export default NotificationPermission;
