// src/slices/notificationSlice.js

import { createSlice } from '@reduxjs/toolkit';

const notificationsSlice = createSlice({
    name: 'notifications',
    initialState: {
        messages: {}, // Store messages per room
        callNotifications: [],
        errors: [],
    },
    reducers: {
        receiveMessage: (state, action) => {
            const { roomName, id, ...message } = action.payload;
            if (!roomName || !id) {
              console.error("receiveMessage: Missing roomName or id in payload", action.payload);
              return;
            }
            if (!state.messages[roomName]) {
              state.messages[roomName] = [];
            }
            // Prevent duplicate messages
            const exists = state.messages[roomName].some(msg => msg.id === id);
            if (!exists) {
              // Add new message to the end for newest at the bottom
              state.messages[roomName].push({ id, ...message });
            }
          },
          
          prependMessages: (state, action) => {
            const { roomName, messages } = action.payload;
            if (!roomName) {
              console.error("prependMessages: Missing roomName", action.payload);
              return;
            }
            if (!state.messages[roomName]) {
              state.messages[roomName] = [];
            }
            // Filter out duplicates
            const newMessages = messages.filter(
              msg => !state.messages[roomName].some(existingMsg => existingMsg.id === msg.id)
            );
            // Prepend older messages to the beginning
            state.messages[roomName] = [...newMessages, ...state.messages[roomName]];
          },
          
        receiveCallNotification: (state, action) => {
            const { from, callType, roomName, ...rest } = action.payload;
            if (from && callType && roomName) {
                state.callNotifications.push({ from, callType, roomName, ...rest });
            } else {
                console.error('Received invalid call notification payload:', action.payload);
            }
        },
        clearMessages: (state, action) => {
            const { roomName } = action.payload;
            if (roomName) {
                state.messages[roomName] = [];
            } else {
                state.messages = {};
            }
        },
        clearCallNotifications: (state) => {
            state.callNotifications = [];
        },
        receiveError: (state, action) => {
            const { message, roomName } = action.payload;
            if (message) {
                state.errors.push({ message, roomName });
            } else {
                console.error('receiveError: Missing message in payload', action.payload);
            }
        },
        clearErrors: (state) => {
            state.errors = [];
        },
    },
});

export const {
    receiveMessage,
    prependMessages,
    receiveCallNotification,
    clearMessages,
    clearCallNotifications,
    receiveError,
    clearErrors,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;
