import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout } from '../slices/doctorSlice';
import '../CSS/DoctorSidebar.css';

const DoctorSidebar = ({ isOpen, onClose }) => {
  const [isAvailable, setIsAvailable] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleToggleChange = () => {
    setIsAvailable(!isAvailable);
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate('/doctors/login');
  };

  return (
    <div className={`sidebar ${isOpen ? 'open' : ''}`}>
      <div className='side-bar-overlay'>
        <button className="close-sidebar" onClick={onClose}>&times;</button>
        <div className='logo-box'>
          {/* If you want to keep the logo image, retain the <img> tag */}
          <img src='/static/icons/logo.webp' alt='Medbridge-access-logo' className="Medbridge-access-logo" />
          <div className="logo">
            <h1>
              <span className="title-we">Med</span>
              <div className="title-2">
                <span>Bridge</span> <span>Access</span>
              </div>
            </h1>
          </div>
        </div>

        <nav className="nav-items">
          <Link to="/doctors/doctordashboard" className="nav-item" onClick={onClose}>
            <i className="fa-solid fa-house nav-icon"></i> Dashboard
          </Link>
          
          <Link to="/doctors/personal-details" className="nav-item" onClick={onClose}>
            <i className="fa-solid fa-user nav-icon"></i> Personal Details
          </Link>

          {/* Uncomment and update if needed
          <Link to="/doctors/billing" className="nav-item" onClick={onClose}>
            <i className="fa-solid fa-file-invoice nav-icon"></i> Billings
          </Link>
          */}

          <Link to="/doctors/chatpage" className="nav-item" onClick={onClose}>
            <i className="fa-solid fa-message nav-icon"></i> Patients List
          </Link>

          <button onClick={handleLogout} className="nav-item logout-button">
            <i className="fa-solid fa-right-from-bracket nav-icon"></i> Logout
          </button>
        </nav>

        <div className='need-help'>
      <img src="/static/banner/customer.png" alt="Logout Icon" className="need-help-icon" /> Logout
          <div>
            <h4>Need Help?</h4>
            <p>
              Mail us at <a href="mailto:info@medbridgeaccess.com">Support</a> or give us a call at <span>+91 7428557264</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoctorSidebar;
