/* AboutUs.jsx */

import React from 'react';
import { useInView } from 'react-intersection-observer';
import '../CSS/AboutUs.css';

const AboutUs = () => {
  // Intersection Observers for different elements
  const { ref: containerRef, inView: containerVisible } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const { ref: imageRef, inView: imageVisible } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const { ref: borderBoxRef, inView: borderBoxVisible } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const { ref: playButtonRef, inView: playButtonVisible } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const { ref: contentRef, inView: contentVisible } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const { ref: listColumnRef, inView: listColumnVisible } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const { ref: featuresRef, inView: featuresVisible } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const { ref: featureRef, inView: featureVisible } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <div className={`senior-care-container`} ref={containerRef}>
      <div
        className={`senior-care-image ${imageVisible ? 'visible' : ''}`}
        ref={imageRef}
      >
        {/* New Border Box */}
        <div
          className={`border-box ${borderBoxVisible ? 'visible' : ''}`}
          ref={borderBoxRef}
        ></div>

        {/* Existing Image */}
        <img
          src="/static/banner/banner2.webp"
          alt="Nurse with Senior"
          // No need for data-aos attributes
        />

        {/* Play Button with Ripple Effect */}
        <div
          className={`play-button-container ${playButtonVisible ? 'visible' : ''}`}
          ref={playButtonRef}
        >
          <div className="play-button-1" onClick={() => { /* Handle Play Click */ }}>
            <i className="fas fa-play"></i>
          </div>
          <div className="ripple"></div>
          <div className="ripple"></div>
          <div className="ripple"></div>
          <div className="ripple"></div>
          <div className="ripple"></div>
          <div className="ripple"></div>
        </div>
      </div>
      <div
        className={`senior-care-content ${contentVisible ? 'visible' : ''}`}
        ref={contentRef}
      >
        <h2>Welcome to MedBridge Access</h2>
        <h1>Your Gateway to Premium Medical Tourism & Virtual Consultation</h1>
        <p>
          Explore a world where health and travel meet. MedBridge Access is dedicated to providing top-tier medical tourism services alongside virtual consultations for second opinions, ensuring you have the best care possible wherever you are.
        </p>
        <div className="container">
          <div
            className={`list-column ${listColumnVisible ? 'visible' : ''}`}
            ref={listColumnRef}
          >
            <ul>
              <li>Access to world-class healthcare facilities</li>
              <li>Personalized care plans</li>
              <li>Seamless travel and medical arrangements</li>
            </ul>
          </div>
          <div
            className={`list-column ${listColumnVisible ? 'visible' : ''}`}
            ref={listColumnRef}
          >
            <ul>
              <li>Expert second opinions from renowned specialists</li>
              <li>Comprehensive follow-up care</li>
              <li>Ease of access with a state-of-the-art virtual environment integration</li>
            </ul>
          </div>
        </div>
        <div
          className={`senior-care-features ${featuresVisible ? 'visible' : ''}`}
          ref={featuresRef}
        >
          <div
            className={`feature ${featureVisible ? 'visible' : ''}`}
            ref={featureRef}
          >
            <span className="icon">
              <i className="fa-solid fa-piggy-bank"></i>
            </span>
            <span>
              <h4>Health Solutions</h4> Affordable medical tourism
            </span>
          </div>
          <div
            className={`feature ${featureVisible ? 'visible' : ''}`}
            ref={featureRef}
          >
            <span className="icon">
              <i className="fa-solid fa-user-doctor"></i>
            </span>
            <span>
              <h4>Second Opinion </h4>Validate the diagnosis & treatment.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
