import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import UserSidebar from '../Components/UserSidebar';
import MobileHeader from '../Components/MobileHeader';
import '../CSS/PatientDetails.css';
import { updatePatient } from '../slices/userSlice';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const countries = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Brazil",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Comoros",
  "Congo, Democratic Republic of the",
  "Congo, Republic of the",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Fiji",
  "Finland",
  "France",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Greece",
  "Grenada",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "North Korea",
  "North Macedonia",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Qatar",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Korea",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Vatican City",
  "Venezuela",
  "Vietnam",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];
const countryOptions = countries.map((country) => ({
  value: country,
  label: country,
}));

const genderOptions = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
  { value: "other", label: "Other" },
];



const PatientDetails = () => {
  const userState = useSelector((state) => state.user.userData);
  const dispatch = useDispatch();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const {
    user: { username = '', email = '', first_name = '' } = {},
    country = '',
    dob = '',
    gender = '',
    phone = '',
  } = userState?.patient || {};

  const [formData, setFormData] = useState({
    name: first_name,
    email,
    phone,
    gender,
    dob: dob ? new Date(dob) : null,
    country,
  });

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSave = () => {
    setIsEditing(false);

    const updatedPatientData = {
      first_name: formData.name,
      email: formData.email,
      phone: formData.phone,
      gender: formData.gender,
      dob: formData.dob ? formData.dob.toISOString().split('T')[0] : '',
      country: formData.country,
    };

    dispatch(updatePatient(updatedPatientData));

    console.log('Saved Data:', updatedPatientData);
  };

  if (!userState) {
    return <div className="patient-details-loading">Loading patient details...</div>;
  }

  // Define customSelectStyles inside the component to access isEditing
  const customSelectStyles = {
    container: (provided) => ({
      ...provided,
      width: '100%',
      border: 'none',
    }),
    control: (provided, state) => ({
      ...provided,
      backgroundColor: 'white',
      border: 'none',
      boxShadow: 'none',
      outline: 'none',
      borderRadius: '15px',
      minHeight: '38px',
      padding: '0 10px',
      textAlign: 'left',
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 5,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? '#f0f0f0' : '#fff',
      color: '#333',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: isEditing ? '#000' : '#949393', // Change color based on isEditing
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#555',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      color: '#333',
    }),
  };

  return (
    <div className="dashboard">
      <div className="dashboard-overlay">
        <MobileHeader toggleSidebar={toggleSidebar} />
        <div className="dashboard-box">
          <UserSidebar isOpen={isSidebarOpen} onClose={toggleSidebar} />
          <div
            className={`sidebar-overlay ${isSidebarOpen ? 'active' : ''}`}
            onClick={() => setIsSidebarOpen(false)}
          ></div>
          <div className="patient-details-container">
            <div className="patient-details-header">
              <h2>Patient Details</h2>
            </div>
            <form className="patient-details-form">
              {/* Name */}
              <div className="patient-details-form-group">
                <label htmlFor="name" className="patient-details-label">
                  Full Name
                </label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  disabled={!isEditing}
                  className="patient-details-input"
                />
              </div>

              {/* Email */}
              <div className="patient-details-form-group">
                <label htmlFor="email" className="patient-details-label">
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  disabled={!isEditing}
                  className="patient-details-input"
                />
              </div>

              {/* Phone */}
              <div className="patient-details-form-group">
                <label htmlFor="phone" className="patient-details-label">
                  Phone
                </label>
                <input
                  type="text"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  disabled={!isEditing}
                  className="patient-details-input"
                />
              </div>

              {/* Gender */}
              <div className="patient-details-form-group">
                <label htmlFor="gender" className="patient-details-label">
                  Gender
                </label>
                <Select
                  name="gender"
                  value={
                    genderOptions.find((option) => option.value === formData.gender) || null
                  }
                  onChange={(selectedOption) =>
                    setFormData({ ...formData, gender: selectedOption.value })
                  }
                  options={genderOptions}
                  isDisabled={!isEditing}
                  styles={customSelectStyles}
                  placeholder="Select gender"
                  components={{ IndicatorSeparator: () => null }}
                />
              </div>

              {/* Date of Birth */}
              <div className="patient-details-form-group">
                <label htmlFor="dob" className="patient-details-label">
                  Date of Birth
                </label>
                <DatePicker
                  selected={formData.dob}
                  onChange={(date) => setFormData({ ...formData, dob: date })}
                  disabled={!isEditing}
                  className="patient-details-input"
                  dateFormat="yyyy-MM-dd"
                  placeholderText="Select date"
                />
              </div>

              {/* Country */}
              <div className="patient-details-form-group">
                <label htmlFor="country" className="patient-details-label">
                  Country
                </label>
                <Select
                  name="country"
                  value={
                    countryOptions.find((option) => option.value === formData.country) || null
                  }
                  onChange={(selectedOption) =>
                    setFormData({ ...formData, country: selectedOption.value })
                  }
                  options={countryOptions}
                  isDisabled={!isEditing}
                  styles={customSelectStyles}
                  placeholder="Select country"
                  components={{ IndicatorSeparator: () => null }}
                />
              </div>

              {/* Buttons */}
              {!isEditing && (
                <button
                  type="button"
                  className="patient-details-edit-button"
                  onClick={handleEdit}
                >
                  Edit
                </button>
              )}

              {isEditing && (
                <button
                  type="button"
                  className="patient-details-save-button"
                  onClick={handleSave}
                >
                  Save
                </button>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientDetails;