import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAppointments } from '../slices/userSlice';
import styled from 'styled-components';

const ChatListContainer = styled.div`
  width: 400px;
  background-color: #f7f7f7;
  border-right: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: auto;

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    border-right: none;
    border-bottom: 1px solid #ddd;
  }
`;

const LogoBox = styled.div`
  padding: 20px;
  text-align: center;
  border-bottom: 1px solid #ddd;

  .Medbridge-access-logo {
    width: 100px;
    height: auto;
    margin-bottom: 10px;

    @media (max-width: 768px) {
      width: 80px;
    }
  }

  .logo h1 {
    font-size: 1.5rem;
    margin: 0;

    .title-we {
      color: #007bff;
    }

    .title-2 {
      display: flex;
      justify-content: center;

      span {
        margin-left: 5px;
      }
    }

    @media (max-width: 768px) {
      font-size: 1.2rem;

      .title-2 {
        flex-direction: column;

        span {
          margin: 0;
        }
      }
    }
  }
`;

const UserItem = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 20px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: #eee;
  }

  @media (max-width: 768px) {
    padding: 10px 15px;
  }
`;

const UserPhoto = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 15px;
  object-fit: cover;
  flex-shrink: 0;

  @media (max-width: 768px) {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }
`;

const UserInfo = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const UserName = styled.div`
  font-weight: 500;
  text-align: left;
  margin-bottom: 5px;
  font-size: 1rem;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const LastMessage = styled.div`
  font-size: 0.85rem;
  color: #666;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (max-width: 768px) {
    font-size: 0.75rem;
  }
`;

const LastMessageTime = styled.div`
  font-size: 0.75rem;
  color: #666;
  white-space: nowrap;
  margin-left: 10px;
  flex-shrink: 0;

  @media (max-width: 768px) {
    font-size: 0.65rem;
    margin-left: 5px;
  }
`;

const NoAppointments = styled.div`
  padding: 20px;
  text-align: center;
  color: #666;
  font-size: 1rem;

  @media (max-width: 768px) {
    padding: 15px;
    font-size: 0.9rem;
  }
`;

const ChatList = ({ setSelectedUser }) => {
  const dispatch = useDispatch();
  const { appointments, status, error, userData } = useSelector((state) => state.user);

  useEffect(() => {
    if (userData && userData.access_token) {  // Adjusted the token check
      dispatch(fetchAppointments());
    } else {
      console.log('User not logged in, redirecting to login page.');
      // Optionally, redirect to login page or show a message
    }
  }, [dispatch, userData]);

  if (status === 'loading') {
    return <ChatListContainer><NoAppointments>Loading...</NoAppointments></ChatListContainer>;
  }

  if (status === 'failed') {
    return (
      <ChatListContainer>
        <NoAppointments>{error ? error.toString() : 'Failed to load appointments'}</NoAppointments>
      </ChatListContainer>
    );
  }

  return (
    <ChatListContainer>
      <div className='logo-box'>
        <img src='/static/icons/logo.webp' alt='Medbridge-access-logo' className="Medbridge-access-logo" />
        <div className="logo">
          <h1>
            <span className="title-we">Med</span>
            <div className="title-2">
              <span>Bridge</span> <span>Access</span>
            </div>
          </h1>
        </div>
      </div>
      {console.log(appointments,'li')}
      {Array.isArray(appointments) && appointments.length > 0 ? (
        appointments.map((appointment, index) => (
          <UserItem key={index} onClick={() => setSelectedUser(appointment.doctor)}>
            <UserPhoto 
              src={appointment.doctor?.profile_photo || ''} 
              alt={`${appointment.doctor?.user?.first_name || 'Doctor'}'s photo`} 
            />
            <UserInfo>
              <UserName>
                {appointment.doctor?.user?.first_name || 'Unknown'} {appointment.doctor?.user?.last_name || ''}
              </UserName>
              <LastMessage>{appointment.problem_description || 'No description available'}</LastMessage>
            </UserInfo>
            <LastMessageTime>
              {appointment.appointment_time 
                ? new Date(appointment.appointment_time).toLocaleString() 
                : 'Time not set'}
            </LastMessageTime>
          </UserItem>
        ))
      ) : (
        <NoAppointments>No appointments available</NoAppointments>
      )}
    </ChatListContainer>
  );
};

export default ChatList;
