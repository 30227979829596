import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import '../CSS/Header.css';

const Header = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const closeSidebar = () => {
    setSidebarOpen(false);
  };

  // Function to determine active class
  const getActiveClass = ({ isActive }) => (isActive ? 'active' : '');

  return (
    <>
      <header className="header">
        <div className="header-strip">
          <p>Phone: +91 11 6926 1933 * Email: info@medbridgeaccess.com* Time: 9:00AM-8:00PM</p>
          <div className="social-icons">
            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" className="social-icon">
              <i className="fa-brands fa-facebook"></i>
            </a>
            <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer" className="social-icon">
              <i className="fa-brands fa-twitter"></i>
            </a>
            <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer" className="social-icon">
              <i className="fa-brands fa-linkedin"></i>
            </a>
          </div>
        </div>
        <div className="header-container">
          <div className="logo-box">
            <img src="/static/icons/logo.webp" alt="Medbridge-access-logo" className="Medbridge-access-logo" />
            <div className="logo">
              <h1>
                <span className="title-we">Med</span>
                <div className="title-2"><span>Bridge</span> <span>Access</span></div>
              </h1>
            </div>
          </div>
          <nav className="nav-links">
            <NavLink to="/" className={getActiveClass} end>
              Home
            </NavLink>
            <NavLink to="/aboutus" className={getActiveClass}>
              About
            </NavLink>
            <NavLink to="/services" className={getActiveClass}>
              Services
            </NavLink>
            <NavLink to="/medicaltourism" className={getActiveClass}>
              Medical Tourism
            </NavLink>
            <NavLink to="/foundermessage" className={getActiveClass}>
              Founders Message
            </NavLink>
          </nav>
          <NavLink to="/bookappointment" className="quote-button-link">
            <button className="quote-button">Book An Appointment</button>
          </NavLink>
          <button className="menu-button" onClick={toggleSidebar}>
            <i className="fa fa-bars"></i>
          </button>
        </div>
      </header>
      <div className={`header-sidebar ${sidebarOpen ? 'open' : ''}`}>
        <button className="close-header-sidebar" onClick={closeSidebar}>
          <i className="fa fa-times"></i>
        </button>
        <nav className="header-sidebar-links">
          <NavLink to="/" className={getActiveClass} end onClick={closeSidebar}>
            Home
          </NavLink>
          <NavLink to="/aboutus" className={getActiveClass} onClick={closeSidebar}>
            About
          </NavLink>
          <NavLink to="/services" className={getActiveClass} onClick={closeSidebar}>
            Services
          </NavLink>
          <NavLink to="/medicaltourism" className={getActiveClass} onClick={closeSidebar}>
            Medical Tourism
          </NavLink>
          <NavLink to="/foundermessage" className={getActiveClass} onClick={closeSidebar}>
            Founders Message
          </NavLink>
          <NavLink to="/bookappointment" className={getActiveClass} onClick={closeSidebar}>
            Book An Appointment
          </NavLink>
        </nav>
      </div>
      <div className={`backdrop ${sidebarOpen ? 'show' : ''}`} onClick={closeSidebar}></div>
    </>
  );
};

export default Header;
