import React, { useState, useEffect, useRef } from 'react';
import { FaHeart, FaHeartbeat, FaEllipsisV, FaPlus } from 'react-icons/fa';
import '../CSS/PatientProfile.css'; // Ensure this CSS file contains styles for the classes used

const calculateAge = (dob) => {
  const birthDate = new Date(dob);
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDifference = today.getMonth() - birthDate.getMonth();

  if (
    monthDifference < 0 ||
    (monthDifference === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--;
  }

  return age;
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString();
};

const PatientProfile = ({ selectedUser, onStartChat }) => {
  // Extract data from selectedUser
  const name = `${selectedUser?.user?.first_name} ${selectedUser?.user?.last_name}` || 'Unknown';
  const gender = selectedUser?.gender || 'Unknown';
  const dob = selectedUser?.dob || 'Unknown';
  const medical_histories = selectedUser?.medical_histories || [];
  const medical_reports = selectedUser?.medical_reports || [];
  const vitals = selectedUser?.vitals || [];

  // Get the latest vitals
  const latestVitals = vitals.length > 0 ? vitals[vitals.length - 1] : {};

  const bloodPressure = latestVitals.blood_pressure || 'N/A';
  const pulse = latestVitals.pulse_rate || 'N/A';
  const height = latestVitals.height || 'N/A';
  const weight = latestVitals.weight || 'N/A';

  // Assume 'problem' is the latest medical history description
  const problem =
    medical_histories.length > 0
      ? medical_histories[medical_histories.length - 1].description
      : 'N/A';

  // Prepare medical history descriptions
  const medicalHistory = medical_histories
    .map((history) => history.description)
    .join(', ');

  // Process lab results from medical_reports
  const labResults = medical_reports.map((report) => {
    const fileName = report.file ? report.file.split('/').pop() : 'Unknown';
    const uploadedAt = report.uploaded_at || 'Unknown';
    const date = uploadedAt.split('T')[0];
    const fileType = fileName.split('.').pop();
    return {
      name: fileName,
      date: date,
      fileType: fileType,
    };
  });

  // State for menu visibility
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  // Toggle menu
  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
  };

  // Close menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    if (menuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuOpen]);

  // Menu actions
  const handleViewDetails = () => {
    alert('View Details clicked');
    setMenuOpen(false);
  };

  const handleEditProfile = () => {
    alert('Edit Profile clicked');
    setMenuOpen(false);
  };

  const handleDeleteProfile = () => {
    alert('Delete Profile clicked');
    setMenuOpen(false);
  };

  return (
    <div className="patient-profile-container">
      {/* Profile Header */}
      <div className="profile-header">
        
        <div className="profile-info">
        <div className="profile-initial">{name.charAt(0)}</div>
          <h2>{name}</h2>
          <p>{gender}</p>
        </div>
     
      </div>

      {/* Demographics */}
      <div className="demographics">
        <div className="demo-row">
          <div className="demo-item">
            <span className="label">DOB</span>
            <span className="value">{formatDate(dob)}</span>
          </div>
          <div className="demo-item">
            <span className="label">Age</span>
            <span className="value">{calculateAge(dob)}</span>
          </div>
        </div>
        <div className="demo-row">
          <div className="demo-item">
            <span className="label">Weight</span>
            <span className="value">{weight}</span>
          </div>
          <div className="demo-item">
            <span className="label">Height</span>
            <span className="value">{height}</span>
          </div>
        </div>
      </div>

      {/* Health Metrics */}
      <div className="health-metrics-container">
        <div className="health-metrics-header">
          <h2>Your Vitals</h2>
          <button className="health-metrics-see-all">Update</button>
        </div>

        <div className="health-metrics-grid">
          <div className="health-metrics-card">
            <div className="health-metrics-info">
              <span className="health-metrics-title">Blood Pressure</span>
              <span className="health-metrics-value">{bloodPressure}</span>
            </div>
            <div className="health-metrics-icon">
              <FaHeart />
            </div>
          </div>

          <div className="health-metrics-card">
            <div className="health-metrics-info">
              <span className="health-metrics-title">Pulse</span>
              <span className="health-metrics-value">{pulse} BPM</span>
            </div>
            <div className="health-metrics-icon">
              <FaHeartbeat />
            </div>
          </div>
          {/* Add more metrics if needed */}
        </div>
      </div>

      {/* Current Problem */}
      <div className="patient-notes">
        <div className="notes-header">
          <h3>Current Problem</h3>
          <div className="notes-actions">
            <FaPlus className="action-icon" />
            <FaEllipsisV className="action-icon" />
          </div>
        </div>
        <div className="notes-content">
          <p className="notes-text">{problem}</p>
        </div>
      </div>

      {/* Medical History */}
      <div className="medical-history">
        <div className="history-header">
          <h2>Medical History</h2>
          <div className="history-actions">
            <a href="#" className="see-all">
              See All
            </a>
          </div>
        </div>
        <div className="history-content">
          {medical_histories.length > 0 ? (
            medical_histories.map((item, index) => (
              <div className="history-item" key={index}>
                <p className="history-date">{formatDate(item.created_at)}</p>
                <h4 className="history-title">{item.category}</h4>
                <p className="history-description">{item.description}</p>
              </div>
            ))
          ) : (
            <h4 className="not-found-message">No medical history found.</h4>
          )}
        </div>
      </div>

      {/* Medical Reports */}
      <div className="medical-reports">
        <div className="reports-header">
          <h2>Medical Reports</h2>
          <FaEllipsisV className="menu-icon" />
        </div>
        <div className="reports-content">
          {medical_reports.length > 0 ? (
            medical_reports.map((report, index) => (
              <a
                key={index}
                href={report.file || '#'}
                target="_blank"
                rel="noopener noreferrer"
                className="report-item"
              >
                <div className="report-details">
                  <div className="report-icon">
                    <i className="fa-regular fa-file-lines"></i>
                  </div>
                  <p className="report-name">
                    {report.file ? report.file.split('/').pop() : 'Unknown'}
                  </p>
                  <p className="report-date">
                    {formatDate(report.uploaded_at)}
                  </p>
                </div>
              </a>
            ))
          ) : (
            <h4 className="not-found-message">No medical reports found.</h4>
          )}
        </div>
      </div>

      {/* Start Chat Button */}
      {!onStartChat && (
        <button className="start-chat-button" onClick={onStartChat}>
          Start Chat
        </button>
      )}
    </div>
  );
};

export default PatientProfile;
