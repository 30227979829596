// AppointmentDashboard.jsx

import React, { useState, useEffect, forwardRef } from 'react';
import { ChevronDown, Clock, Edit } from 'lucide-react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../CSS/AdminDashboard.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchAppointments,
  fetchDoctors,
  updateAppointment,
} from '../slices/adminSlice';
import { useNavigate } from 'react-router-dom';
import AdminNavbar from '../Components/AdminNavbar';

const CustomDateInput = forwardRef(({ value, onClick }, ref) => (
  <div
    className="appointment-dashboard-editable-date-time"
    onClick={onClick}
    ref={ref}
    title="Click to edit date and time"
    tabIndex={0}
    onKeyPress={(e) => {
      if (e.key === 'Enter') {
        onClick();
      }
    }}
  >
    <Clock size={16} /> {value}
  </div>
));

const AppointmentDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [expandedAppointment, setExpandedAppointment] = useState(null);
  const [editingAppointment, setEditingAppointment] = useState(null);
  const [editedFields, setEditedFields] = useState({});
  const appointments = useSelector((state) => state.admin.appointments);
  const appointmentStatus = useSelector((state) => state.admin.appointmentStatus);
  const appointmentError = useSelector((state) => state.admin.appointmentError);
  const doctors = useSelector((state) => state.admin.doctors);
  const doctorStatus = useSelector((state) => state.admin.doctorStatus);
  const adminToken = useSelector((state) => state.admin.adminToken);

  useEffect(() => {
    if (!adminToken) {
      navigate('/admin/login');
    }
  }, [adminToken, navigate]);

  useEffect(() => {
    if (appointmentStatus === 'idle') {
      dispatch(fetchAppointments());
    }
    if (doctorStatus === 'idle') {
      dispatch(fetchDoctors());
    }
  }, [appointmentStatus, doctorStatus, dispatch]);

  const toggleExpand = (id) => {
    setExpandedAppointment(expandedAppointment === id ? null : id);
  };

  const handleEdit = (appointment) => {
    setEditingAppointment(appointment.id);
    setEditedFields({
      status: appointment.status,
      // **Updated Line Below**
      doctor: appointment.doctor ? appointment.doctor.id.toString() : '', // Use doctor.id and convert to string
      appointment_time: appointment.appointment_time,
    });
  };

  const handleSave = (appointment) => {
    const data = {};

    if (editedFields.status !== appointment.status) {
      data.status = editedFields.status;
    }
    if (
      editedFields.doctor !== undefined &&
      editedFields.doctor !== (appointment.doctor ? appointment.doctor.id.toString() : '')
    ) {
      data.doctor = editedFields.doctor ? parseInt(editedFields.doctor, 10) : null; // Convert back to number
    }
    if (editedFields.appointment_time !== appointment.appointment_time) {
      data.appointment_time = editedFields.appointment_time;
    }

    if (Object.keys(data).length > 0) {
      console.log('Dispatching updateAppointment with data:', data); // Debugging
      dispatch(updateAppointment({ id: appointment.id, data }));
    }

    setEditingAppointment(null);
    setEditedFields({});
  };

  const handleCancel = () => {
    setEditingAppointment(null);
    setEditedFields({});
  };

  const formatDateTime = (dateStr) => {
    const date = new Date(dateStr);
    return date.toLocaleString([], {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    return date.toLocaleDateString(undefined, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  const formatTime = (dateStr) => {
    const date = new Date(dateStr);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  return (
    <div className="admin-dashboard-container">
      <AdminNavbar /> {/* Include the navbar */}
      <div className="admin-dashboard-content">
        <div className="appointment-dashboard-header">
          <h2>Appointments</h2>
        </div>

        {appointmentStatus === 'loading' && <p>Loading appointments...</p>}
        {appointmentStatus === 'failed' && <p>Error: {appointmentError}</p>}

        {appointmentStatus === 'succeeded' && (
          <div className="appointment-dashboard-appointments-list">
            <div className="appointment-dashboard-appointment-header">
              <div>ID</div>
              <div>Patient</div>
              <div>Doctor</div>
              <div>Date & Time</div>
              <div>Assign Doctor</div>
              <div>Status</div>
              <div>Actions</div>
              <div>Details</div>
            </div>

            {appointments.map((appointment) => (
              <React.Fragment key={appointment.id}>
                <div className="appointment-dashboard-appointment-item">
                  <div>{appointment.id}</div>
                  <div className="appointment-dashboard-user-info">
                    <div className="appointment-dashboard-user-avatar">👤</div>
                    <span>
                      {appointment.patient.user.first_name}{' '}
                      {appointment.patient.user.last_name}
                    </span>
                  </div>
                  <div>
                    {appointment.doctor
                      ? `${appointment.doctor.user.first_name} ${appointment.doctor.user.last_name}`
                      : 'Not Assigned'}
                  </div>
                  <div>
                    {editingAppointment === appointment.id ? (
                      <DatePicker
                        selected={
                          editedFields.appointment_time
                            ? new Date(editedFields.appointment_time)
                            : appointment.appointment_time
                            ? new Date(appointment.appointment_time)
                            : null
                        }
                        onChange={(date) =>
                          setEditedFields({
                            ...editedFields,
                            appointment_time: date.toISOString(),
                          })
                        }
                        showTimeSelect
                        dateFormat="MMMM d, yyyy h:mm aa"
                        customInput={<CustomDateInput />}
                      />
                    ) : appointment.appointment_time ? (
                      formatDateTime(appointment.appointment_time)
                    ) : (
                      'Not Scheduled'
                    )}
                  </div>
                  <div>
                    {editingAppointment === appointment.id ? (
                      <select
                        className="appointment-dashboard-doctor-select"
                        value={editedFields.doctor}
                        onChange={(e) =>
                          setEditedFields({
                            ...editedFields,
                            doctor: e.target.value, // Keep as string
                          })
                        }
                      >
                        <option value="">Select Doctor</option>
                        {doctors.map((doctor) => (
                          <option key={doctor.id} value={doctor.id}>
                            {doctor.user.first_name} {doctor.user.last_name} -{' '}
                            {doctor.specialization}
                          </option>
                        ))}
                      </select>
                    ) : appointment.doctor ? (
                      `${appointment.doctor.user.first_name} ${appointment.doctor.user.last_name}`
                    ) : (
                      'Not Assigned'
                    )}
                  </div>
                  <div>
                    {editingAppointment === appointment.id ? (
                      <select
                        className="appointment-dashboard-status-select"
                        value={editedFields.status || appointment.status}
                        onChange={(e) =>
                          setEditedFields({
                            ...editedFields,
                            status: e.target.value,
                          })
                        }
                      >
                        <option value="Pending">Pending</option>
                        <option value="Confirmed">Confirmed</option>
                        <option value="Completed">Completed</option>
                        <option value="Cancelled">Cancelled</option>
                      </select>
                    ) : (
                      <span>{appointment.status}</span>
                    )}
                  </div>
                  <div>
                    {editingAppointment === appointment.id ? (
                      <>
                        <button
                          className="appointment-dashboard-save-button"
                          onClick={() => handleSave(appointment)}
                        >
                          Save
                        </button>
                        <button
                          className="appointment-dashboard-cancel-button"
                          onClick={() => handleCancel()}
                        >
                          Cancel
                        </button>
                      </>
                    ) : (
                      <button
                        className="appointment-dashboard-edit-button"
                        onClick={() => handleEdit(appointment)}
                      >
                        <Edit size={16} />
                      </button>
                    )}
                  </div>
                  <button
                    className={`appointment-dashboard-action-button ${
                      expandedAppointment === appointment.id ? 'expanded' : ''
                    }`}
                    onClick={() => toggleExpand(appointment.id)}
                    aria-label="Toggle Details"
                  >
                    <ChevronDown size={18} />
                  </button>
                </div>
                {expandedAppointment === appointment.id && (
                  <div className="appointment-dashboard-appointment-details">
                    <div className="appointment-dashboard-details-grid">
                      <div className="appointment-dashboard-detail-section">
                        <h4>Patient Information</h4>
                        <p>
                          <strong>Name:</strong>{' '}
                          {appointment.patient.user.first_name}{' '}
                          {appointment.patient.user.last_name}
                        </p>
                        <p>
                          <strong>Email:</strong> {appointment.patient.user.email}
                        </p>
                        <p>
                          <strong>Phone:</strong> {appointment.patient.phone}
                        </p>
                      </div>
                      <div className="appointment-dashboard-detail-section">
                        <h4>Doctor Information</h4>
                        {appointment.doctor ? (
                          <>
                            <p>
                              <strong>Name:</strong>{' '}
                              {appointment.doctor.user.first_name}{' '}
                              {appointment.doctor.user.last_name}
                            </p>
                            <p>
                              <strong>Specialization:</strong>{' '}
                              {appointment.doctor.specialization}
                            </p>
                          </>
                        ) : (
                          <p>Doctor not assigned</p>
                        )}
                      </div>
                      <div className="appointment-dashboard-detail-section">
                        <h4>Appointment Details</h4>
                        <p>
                          <strong>Problem Heading:</strong>{' '}
                          {appointment.problem_heading}
                        </p>
                        <p>
                          <strong>Problem Description:</strong>{' '}
                          {appointment.problem_description}
                        </p>
                        <p>
                          <strong>Date:</strong>{' '}
                          {appointment.appointment_time
                            ? formatDate(appointment.appointment_time)
                            : 'Not Scheduled'}
                        </p>
                        <p>
                          <strong>Time:</strong>{' '}
                          {appointment.appointment_time
                            ? formatTime(appointment.appointment_time)
                            : 'Not Scheduled'}
                        </p>
                        <p>
                          <strong>Status:</strong> {appointment.status}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default AppointmentDashboard;
