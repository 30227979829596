import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { adminLogin } from '../slices/adminSlice';
import { useNavigate } from 'react-router-dom';
import '../CSS/AdminDashboard.css';

const AdminLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authStatus = useSelector((state) => state.admin.authStatus);
  const authError = useSelector((state) => state.admin.authError);
  const adminToken = useSelector((state) => state.admin.adminToken);

  const [credentials, setCredentials] = useState({ username: '', password: '' });

  useEffect(() => {
    if (adminToken) {
      navigate('/admin/admin-dashboard');
    }
  }, [adminToken, navigate]);

  const handleChange = (e) => {
    setCredentials({ 
      ...credentials, 
      [e.target.name]: e.target.value 
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const resultAction = await dispatch(adminLogin(credentials));
    if (adminLogin.fulfilled.match(resultAction)) {
      navigate('/admin/admin-dashboard');
    }
  };

  return (
    <div className="admin-login-page">
      <div className="admin-login-container">
        <form className="admin-login-form" onSubmit={handleSubmit}>
          <h2>Admin Login</h2>
          <input 
            type="text" 
            name="username" 
            value={credentials.username} 
            onChange={handleChange} 
            placeholder="Username" 
            required 
          />
          <input 
            type="password" 
            name="password" 
            value={credentials.password} 
            onChange={handleChange} 
            placeholder="Password" 
            required 
          />
          <button type="submit" disabled={authStatus === 'loading'}>
            {authStatus === 'loading' ? 'Logging in...' : 'Login'}
          </button>
          {authError && <p className="error-message">{authError}</p>}
        </form>
      </div>
    </div>
  );
};

export default AdminLogin;
