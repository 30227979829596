// src/App.js

import React, { useEffect, useState } from 'react';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { HashRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { Provider, useSelector } from 'react-redux';
import store from './store';
import Header from './Components/Header';
import Index from './Pages/Index';
import Services from './Pages/Services';
import Footer from './Components/Footer';
import AboutUS from './Pages/AboutUs';
import SignUp from './Pages/BookAnAppointment';
import ChatApp from './Pages/ChatApp';
import HealthcareServiceForm from './Pages/ServiceForm';
import ServicesDetails from './Pages/ServicesDetails';
import MedicalTourism from './Pages/MedicalTourism';
import Dashboard from './Pages/UserDashboard';
import FounderMess from './Pages/FounderMess';
import DoctorDashboard from './Pages/DoctorDashboard';
import DoctorChatApp from './Pages/DoctorChat';
import DoctorRegistrationForm from './Pages/DoctorRegistrationForm';
import LoginPage from './Pages/DoctorLogin';
import PatientDetails from './Pages/PatientDetails';
import TermsAndConditions from './Pages/Terms';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import RefundPolicy from './Pages/Refund';
import ScrollToTop from './Components/ScrollToTop';
import AppointmentDashboard from './Pages/AdminDashboard';
import WhatsAppButton from './Pages/WhatsAppButton';
import DoctorProfileUpdate from './Pages/DoctorDetails';
import DoctorChatPage from './Pages/DoctorChatPage';
import AdminLogin from './Pages/AdminLogin';
import AdminPatientDashboard from './Pages/AdminPatientDashboard';
import AdminDoctorDashboard from './Pages/AdminDoctorDashboard';

import './App.css';

// Import WebSocketManager and Notification components
import webSocketManager from './services/WebSocketManager';
import NotificationPermission from './NotificationPermission';
import GlobalCallNotification from './GlobalCallNotification';
import { generateRoomName } from './utils'; // Import utility function

function App() {
  return (
    <Provider store={store}>
      <div className="App">
        <Router>
          <ScrollToTop />
          <WebSocketInitializer /> {/* Initialize WebSocket Connection */}
          <NotificationPermission /> {/* Request Notification Permission */}
          <GlobalCallNotification /> {/* Handle Global Call Notifications */}
          <Layout>
            <Routes>
              <Route exact path="/" element={<Index />} />
              <Route exact path="/services" element={<Services />} />
              <Route exact path="/services/:serviceId" element={<ServicesDetails />} />
              <Route exact path="/aboutus" element={<AboutUS />} />
              <Route exact path="/bookappointment" element={<SignUp />} />
              <Route exact path="/medicaltourism" element={<MedicalTourism />} />
              <Route exact path="/admin/serviceform" element={<HealthcareServiceForm />} />
              <Route exact path="/userdashboard" element={<Dashboard />} />
              <Route exact path="/foundermessage" element={<FounderMess />} />
              <Route exact path="/doctors/doctordashboard" element={<DoctorDashboard />} />
              <Route exact path="/doctors/chat/:patientId" element={<DoctorChatApp />} />
              <Route exact path="/doctors/registration" element={<DoctorRegistrationForm />} />
              <Route exact path="/doctors/login" element={<LoginPage />} />
              <Route exact path="/doctors/personal-details" element={<DoctorProfileUpdate />} />
              <Route exact path="/terms" element={<TermsAndConditions />} />
              <Route exact path="/privacy_policy" element={<PrivacyPolicy />} />
              <Route exact path="/refund_policy" element={<RefundPolicy />} />
              <Route exact path="/personal-details" element={<PatientDetails />} />
              <Route exact path="/chat" element={<ChatApp />} />
              <Route exact path="/admin/admin-dashboard" element={<AppointmentDashboard />} />
              <Route exact path="/doctors/chatpage" element={<DoctorChatPage />} />
              <Route exact path="/admin/login" element={<AdminLogin />} />
              <Route exact path="/admin/patients" element={<AdminPatientDashboard />} />
              <Route exact path="/admin/doctors" element={<AdminDoctorDashboard />} />
            </Routes>
            {/* Removed WhatsAppButton from here */}
          </Layout>
        </Router>
      </div>
    </Provider>
  );
}

// Define WebSocketInitializer within App.js
const WebSocketInitializer = () => {
  const location = useLocation();
  const doctorData = useSelector((state) => state.doctor.doctorData);
  const userData = useSelector((state) => state.user.userData);
  const [selectedUser, setSelectedUser] = useState(() => {
    const storedUser = localStorage.getItem('selectedUser');
    if (storedUser) {
      return JSON.parse(storedUser);
    } else if (location.state && location.state.selectedUser) {
      return location.state.selectedUser;
    } else {
      return null;
    }
  });

  // Update selectedUser state if location.state changes
  useEffect(() => {
    if (location.state && location.state.selectedUser) {
      setSelectedUser(location.state.selectedUser);
      localStorage.setItem(
        'selectedUser',
        JSON.stringify(location.state.selectedUser)
      );
    }
  }, [location.state]);

  useEffect(() => {
    let token = null;

    if (doctorData && doctorData.access_token) {
      token = doctorData.access_token;
    } else if (userData && userData.access_token) {
      token = userData.access_token;
    } else {
      // Try to get from localStorage
      const storedDoctorData = localStorage.getItem('doctorData');
      const storedUserData = localStorage.getItem('userData');
      if (storedDoctorData) {
        try {
          const parsedDoctorData = JSON.parse(storedDoctorData);
          if (parsedDoctorData.access_token) {
            token = parsedDoctorData.access_token;
          }
        } catch (error) {
          console.error('Error parsing storedDoctorData:', error);
        }
      }
      if (!token && storedUserData) {
        try {
          const parsedUserData = JSON.parse(storedUserData);
          if (parsedUserData.access_token) {
            token = parsedUserData.access_token;
          }
        } catch (error) {
          console.error('Error parsing storedUserData:', error);
        }
      }
    }
   if(userData || doctorData){
    if (token) {
      webSocketManager.connect(token);
    } else {
      console.error('No token available to connect to WebSocket.');
    }
  }

    // Cleanup on unmount
    return () => {
      webSocketManager.close();
    };
  }, [doctorData, userData]);

  useEffect(() => {
    if (!selectedUser) return;

    // Get currentUser from Redux or localStorage
    let currentUserData = doctorData || userData;
    if (!currentUserData) {
      const storedDoctorData = localStorage.getItem('doctorData');
      const storedUserData = localStorage.getItem('userData');
      if (storedDoctorData) {
        try {
          const parsedDoctorData = JSON.parse(storedDoctorData);
          currentUserData = parsedDoctorData;
        } catch (error) {
          console.error('Error parsing storedDoctorData:', error);
        }
      }
      if (!currentUserData && storedUserData) {
        try {
          const parsedUserData = JSON.parse(storedUserData);
          currentUserData = parsedUserData;
        } catch (error) {
          console.error('Error parsing storedUserData:', error);
        }
      }
    }

    if (!currentUserData) return;

    const currentUserId =
      currentUserData?.doctor?.user?.id ||
      currentUserData?.patient?.user?.id ||
      currentUserData?.user?.id ||
      currentUserData.id;
    const selectedUserId = selectedUser.user.id;
    const roomName = generateRoomName(currentUserId, selectedUserId);

    // Subscribe to the generated room
    webSocketManager.subscribeToRoom(roomName);

    // Cleanup: Unsubscribe from the room when selectedUser changes or component unmounts
    return () => {
      webSocketManager.unsubscribeFromRoom(roomName);
    };
  }, [selectedUser, doctorData, userData]);

  return null; // This component doesn't render anything
};

// Define Layout within App.js
function Layout({ children }) {
  const location = useLocation();

  const noHeaderFooterRoutes = [
    '/userdashboard',
    '/doctors/doctordashboard',
    '/chat',
    '/doctors/registration',
    '/doctors/login',
    '/personal-details',
    '/admin/admin-dashboard',
    '/doctors/personal-details',
    '/doctors/chatpage',
    '/admin/login',
    '/admin/patients',
    '/admin/doctors',
  ];

  // Determine if Header and Footer should be hidden
  const isHeaderFooterHidden =
    noHeaderFooterRoutes.includes(location.pathname) ||
    location.pathname.startsWith('/doctors/chat/');

  // Define chat routes to exclude WhatsAppButton
  const isChatPage =
    location.pathname === '/chat' ||
    location.pathname === '/doctors/chatpage' ||
    location.pathname.startsWith('/doctors/chat/');

  return (
    <>
      {!isHeaderFooterHidden && <Header />}
      <main>
        {children}
      </main>
      {!isHeaderFooterHidden && <Footer />}
      {/* Conditionally render WhatsAppButton */}
      {!isChatPage && <WhatsAppButton />}
    </>
  );
}

export default App;
