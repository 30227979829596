// src/GlobalCallNotification.js

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { clearCallNotifications } from './slices/notificationSlice';
import webSocketManager from './services/WebSocketManager';

const GlobalCallNotification = () => {
  const callNotifications = useSelector((state) => state.notifications.callNotifications);
  const dispatch = useDispatch();

  useEffect(() => {
    if (callNotifications.length > 0) {
      const latestCall = callNotifications[callNotifications.length - 1];
      
      // Automatically handle the call prompt
      const accept = window.confirm(`Incoming ${latestCall.call_type} call from ${latestCall.from}. Do you want to accept?`);

      if (accept) {
        // Redirect to the ChatWindow or handle the call acceptance logic
        // Example: Navigate to a specific route or open a modal
        // For demonstration, we'll assume a function handleAcceptCall exists
        // handleAcceptCall(latestCall);
        // Since this component doesn't have access to routing, you might need to use additional state management or context
        // Here, we'll just log the acceptance
        console.log(`Accepted call from ${latestCall.from}`);

        // Implement your acceptance logic here
        // e.g., navigate to the chat page or open the VideoCall component
      } else {
        // Decline the call
        webSocketManager.send({ type: 'end-call', to: latestCall.from });
      }

      // Clear the call notification
      dispatch(clearCallNotifications());
    }
  }, [callNotifications, dispatch]);

  return null; // This component doesn't render anything
};

export default GlobalCallNotification;
