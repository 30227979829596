// adminSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Helper function to make authenticated fetch requests
const authFetch = async (url, options = {}, token) => {
  const headers = {
    'Content-Type': 'application/json',
    ...(options.headers || {}),
    ...(token && { Authorization: `Bearer ${token}` }),
  };

  const response = await fetch(url, { ...options, headers });

  if (!response.ok) {
    const errorData = await response.json();
    throw errorData;
  }

  return response.json();
};

// ========================
// Admin Authentication Thunks
// ========================

// Thunk to handle admin login
export const adminLogin = createAsyncThunk(
  'admin/adminLogin',
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await fetch('/api/admin/login/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(credentials),
      });

      if (!response.ok) {
        const errorData = await response.json();
        return rejectWithValue(errorData);
      }

      const data = await response.json();
      // Assuming the response contains tokens in the format { access_token: '...', refresh_token: '...' }
      return data;
    } catch (err) {
      return rejectWithValue({ detail: 'Network error' });
    }
  }
);

// ========================
// Data Management Thunks
// ========================

// Thunk to fetch appointments
export const fetchAppointments = createAsyncThunk(
  'admin/fetchAppointments',
  async (_, { getState, rejectWithValue }) => {
    const { adminToken } = getState().admin;
    try {
      const data = await authFetch('/api/admin/appointments/', {}, adminToken);
      console.log(data)
      return data;
    } catch (err) {
      return rejectWithValue(err.detail || 'Failed to fetch appointments');
    }
  }
);

// Thunk to fetch patients
export const fetchPatients = createAsyncThunk(
  'admin/fetchPatients',
  async (_, { getState, rejectWithValue }) => {
    const { adminToken } = getState().admin;
    try {
      const data = await authFetch('/api/admin/patients/', {}, adminToken);
      return data;
    } catch (err) {
      return rejectWithValue(err.detail || 'Failed to fetch patients');
    }
  }
);

// Thunk to fetch doctors
export const fetchDoctors = createAsyncThunk(
  'admin/fetchDoctors',
  async (_, { getState, rejectWithValue }) => {
    const { adminToken } = getState().admin;
    try {
      const data = await authFetch('/api/admin/doctors/', {}, adminToken);
      return data;
    } catch (err) {
      return rejectWithValue(err.detail || 'Failed to fetch doctors');
    }
  }
);

// Thunk to update appointment (e.g., status, doctor assignment)
export const updateAppointment = createAsyncThunk(
  'admin/updateAppointment',
  async ({ id, data }, { getState, rejectWithValue }) => {
    const { adminToken } = getState().admin;
    try {
      const responseData = await authFetch(
        `/api/admin/appointments/${id}/`,
        {
          method: 'PATCH',
          body: JSON.stringify(data),
        },
        adminToken
      );
      return responseData;
    } catch (err) {
      return rejectWithValue(err.detail || 'Failed to update appointment');
    }
  }
);

// ========================
// Admin Slice
// ========================

const adminSlice = createSlice({
  name: 'admin',
  initialState: {
    // ----------------
    // Authentication State
    // ----------------
    adminToken: localStorage.getItem('adminToken') || null,
    authStatus: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    authError: null,

    // ----------------
    // Appointments State
    // ----------------
    appointments: [],
    appointmentStatus: 'idle',
    appointmentError: null,

    // ----------------
    // Patients State
    // ----------------
    patients: [],
    patientStatus: 'idle',
    patientError: null,

    // ----------------
    // Doctors State
    // ----------------
    doctors: [],
    doctorStatus: 'idle',
    doctorError: null,
  },
  reducers: {
    // Synchronous reducer to handle admin logout
    adminLogout(state) {
      state.adminToken = null;
      state.authStatus = 'idle';
      state.authError = null;
      localStorage.removeItem('adminToken');
      // If you stored the refresh token, remove it as well
      // localStorage.removeItem('adminRefreshToken');
    },
  },
  extraReducers: (builder) => {
    builder
      // ========================
      // Admin Login
      // ========================
      .addCase(adminLogin.pending, (state) => {
        state.authStatus = 'loading';
        state.authError = null;
      })
      .addCase(adminLogin.fulfilled, (state, action) => {
        state.authStatus = 'succeeded';
        state.adminToken = action.payload.access_token; // Use access_token from API response
        localStorage.setItem('adminToken', action.payload.access_token);
        // If you need to store the refresh token
        // localStorage.setItem('adminRefreshToken', action.payload.refresh_token);
      })
      .addCase(adminLogin.rejected, (state, action) => {
        state.authStatus = 'failed';
        state.authError = action.payload?.error || 'Failed to login';
      })

      // ========================
      // Fetch Appointments
      // ========================
      .addCase(fetchAppointments.pending, (state) => {
        state.appointmentStatus = 'loading';
        state.appointmentError = null;
      })
      .addCase(fetchAppointments.fulfilled, (state, action) => {
        state.appointmentStatus = 'succeeded';
        state.appointments = action.payload;
      })
      .addCase(fetchAppointments.rejected, (state, action) => {
        state.appointmentStatus = 'failed';
        state.appointmentError = action.payload || action.error.message;
      })

      // ========================
      // Fetch Patients
      // ========================
      .addCase(fetchPatients.pending, (state) => {
        state.patientStatus = 'loading';
        state.patientError = null;
      })
      .addCase(fetchPatients.fulfilled, (state, action) => {
        state.patientStatus = 'succeeded';
        state.patients = action.payload;
      })
      .addCase(fetchPatients.rejected, (state, action) => {
        state.patientStatus = 'failed';
        state.patientError = action.payload || action.error.message;
      })

      // ========================
      // Fetch Doctors
      // ========================
      .addCase(fetchDoctors.pending, (state) => {
        state.doctorStatus = 'loading';
        state.doctorError = null;
      })
      .addCase(fetchDoctors.fulfilled, (state, action) => {
        state.doctorStatus = 'succeeded';
        state.doctors = action.payload;
      })
      .addCase(fetchDoctors.rejected, (state, action) => {
        state.doctorStatus = 'failed';
        state.doctorError = action.payload || action.error.message;
      })

      // ========================
      // Update Appointment
      // ========================
      .addCase(updateAppointment.pending, (state) => {
        state.appointmentStatus = 'loading';
        state.appointmentError = null;
      })
      .addCase(updateAppointment.fulfilled, (state, action) => {
        state.appointmentStatus = 'succeeded';
        const index = state.appointments.findIndex(
          (appointment) => appointment.id === action.payload.id
        );
        if (index !== -1) {
          state.appointments[index] = action.payload;
        }
      })
      .addCase(updateAppointment.rejected, (state, action) => {
        state.appointmentStatus = 'failed';
        state.appointmentError = action.payload || action.error.message;
      });
  },
});

// Export synchronous actions
export const { adminLogout } = adminSlice.actions;

// Export the reducer to be included in the store
export default adminSlice.reducer;
