// Components/VitalsModal.jsx

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addVitals } from '../slices/userSlice';

const VitalsModal = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const vitals = useSelector((state) => state.user.userData.patient.vitals || []);
  const latestVitals = vitals[0] || {};

  const [vitalsData, setVitalsData] = useState({
    blood_pressure_systolic: '',
    blood_pressure_diastolic: '',
    pulse_rate: '',
    oxygen_level: '',
    weight: '',
    height: '',
  });

  const status = useSelector((state) => state.user.status);
  const error = useSelector((state) => state.user.error);

  useEffect(() => {
    if (isOpen) {
      setVitalsData({
        blood_pressure_systolic: latestVitals.blood_pressure_systolic || '',
        blood_pressure_diastolic: latestVitals.blood_pressure_diastolic || '',
        pulse_rate: latestVitals.pulse_rate || '',
        oxygen_level: latestVitals.oxygen_level || '',
        weight: latestVitals.weight || '',
        height: latestVitals.height || '',
      });
    }
  }, [isOpen]);

  const handleChange = (e) => {
    setVitalsData({
      ...vitalsData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Combine systolic and diastolic into a single blood_pressure value if needed
    const blood_pressure = `${vitalsData.blood_pressure_systolic}/${vitalsData.blood_pressure_diastolic}`;

    const formattedData = {
      ...vitalsData,
      blood_pressure,
    };

    dispatch(addVitals(formattedData))
      .unwrap()
      .then(() => {
        onClose();
      })
      .catch((err) => {
        console.error('Failed to add vitals:', err);
      });
  };

  if (!isOpen) return null;

  return (
    <div className="vitals-modal-overlay">
      <div className="vitals-modal-content">
        <h2 className="vitals-modal-title">Update Vitals</h2>
        <form onSubmit={handleSubmit}>
          <div className="vitals-modal-field blood-pressure-field">
            <label>Blood Pressure</label>
            <div className="blood-pressure-inputs">
              <input
                type="number"
                name="blood_pressure_systolic"
                value={vitalsData.blood_pressure_systolic}
                onChange={handleChange}
                placeholder="Systolic"
              />
              <span>/</span>
              <input
                type="number"
                name="blood_pressure_diastolic"
                value={vitalsData.blood_pressure_diastolic}
                onChange={handleChange}
                placeholder="Diastolic"
              />
            </div>
          </div>

          <div className="vitals-modal-field">
            <label htmlFor="pulse_rate">Pulse Rate</label>
            <input
              type="number"
              name="pulse_rate"
              value={vitalsData.pulse_rate}
              onChange={handleChange}
            />
          </div>
          <div className="vitals-modal-field">
            <label htmlFor="oxygen_level">Oxygen Level</label>
            <input
              type="number"
              name="oxygen_level"
              value={vitalsData.oxygen_level}
              onChange={handleChange}
            />
          </div>
          <div className="vitals-modal-field">
            <label htmlFor="weight">Weight (kg)</label>
            <input
              type="number"
              name="weight"
              value={vitalsData.weight}
              onChange={handleChange}
            />
          </div>
          <div className="vitals-modal-field">
            <label htmlFor="height">Height (cm)</label>
            <input
              type="number"
              name="height"
              value={vitalsData.height}
              onChange={handleChange}
            />
          </div>
          {error && <div className="vitals-modal-error">{error}</div>}
          <div className="vitals-modal-actions">
            <button type="submit" disabled={status === 'loading'}>
              {status === 'loading' ? 'Updating...' : 'Update'}
            </button>
            <button type="button" onClick={onClose} disabled={status === 'loading'}>
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default VitalsModal;
