// Dashboard.js

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import UserSidebar from "../Components/UserSidebar";
import MobileHeader from "../Components/MobileHeader";
import { Navigate, useNavigate } from "react-router-dom";
import "../CSS/UserDashboard.css";
import AppointmentModal from "../Components/AppointmentModal";
import QuoteModal from "../Components/QuoteModal";
import { FaPlus, FaEllipsisV } from "react-icons/fa";
import { fetchAppointments } from "../slices/userSlice";
import '../CSS/VitalModal.css';
import VitalsModal from "../Components/VitalModal";

import {
  Activity,
  Droplet,
  Heart,
  Monitor,
  Thermometer,
  Zap,
} from "lucide-react";

const Dashboard = () => {
  const today = new Date();
  const day = today.getDate();
  const month = today.toLocaleString("default", { month: "long" });

  const [isEditable, setIsEditable] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userState = useSelector((state) => state.user.userData);
  const appointments = useSelector((state) => state.user.appointments);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isQuoteModalOpen, setIsQuoteModalOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isVitalsModalOpen, setIsVitalsModalOpen] = useState(false);
  const vitals = userState.patient.vitals || [];
  const latestVitals = vitals[0] || {};

  const weight = latestVitals.weight || 'N/A';
  const height = latestVitals.height || 'N/A';
  const bmi = latestVitals.bmi || 'N/A';

  let weightStatus = '';
  if (bmi !== 'N/A') {
    if (bmi < 18.5) {
      weightStatus = 'Underweight';
    } else if (bmi >= 18.5 && bmi < 25) {
      weightStatus = 'Normal';
    } else if (bmi >= 25 && bmi < 30) {
      weightStatus = 'Overweight';
    } else {
      weightStatus = 'Obese';
    }
  }

  const metrics = [
    {
      title: 'Blood Pressure',
      value:
        latestVitals.blood_pressure
          ? `${latestVitals.blood_pressure}`
          : 'N/A',
      status: 'Normal',
      icon: (
        <img
          src="static/icons/cardiogram.png"
          alt=""
          className="blood pressure"
        />
      ),
    },
    {
      title: 'Pulse Rate',
      value: latestVitals.pulse_rate ? `${latestVitals.pulse_rate} bpm` : 'N/A',
      status: 'Good',
      icon: <img src="static/icons/pulse.png" alt="" className="pulse" />,
    },
    {
      title: 'Oxygen Level',
      value: latestVitals.oxygen_level ? `${latestVitals.oxygen_level}%` : 'N/A',
      status: 'Excellent',
      icon: <img src="static/icons/oxygen.png" alt="" className="oxygen" />,
    },
    {
      title: 'Weight',
      value: `${weight} kg`,
      status: weightStatus,
      icon: <img src="static/icons/weight.png" alt="" className="weight" />,
    },
    {
      title: 'Height',
      value: `${height} cm`,
      status: '',
      icon: <img src="static/icons/height.png" alt="" className="height" />,
    },
    {
      title: 'BMI',
      value: bmi !== 'N/A' ? bmi.toFixed(1) : 'N/A',
      status: weightStatus,
      icon: <img src="static/icons/bmi.png" alt="" className="bmi" />,
    },
  ];

  useEffect(() => {
    if (userState && userState.patient) {
      dispatch(fetchAppointments());
    }
  }, [dispatch, userState]);

  if (!userState || !userState.patient) {
    return <Navigate to="/bookappointment" />;
  }

  const {
    user: { username = "", email = "", first_name = "", last_name = "" } = {},
    country = "",
    dob = "",
    gender = "",
    phone = "",
    medical_histories = [],
    medical_reports = [],
  } = userState.patient;

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const formatDate = (dateString) => {
    if (!dateString) return "-";
    const options = { day: "numeric", month: "short", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };

  const mapCategoryName = (category) => {
    const categoryMap = {
      registration: "Registration",
      appointment: "Appointment",
      diagnosis: "Diagnosis",
    };
    return categoryMap[category] || category || "-";
  };

  const defaultProfilePhoto = "/static/icons/default_profile_photo.webp";

  const colors = [
    "#FFB6C1",
    "#ADD8E6",
    "#90EE90",
    "#FFA07A",
    "#20B2AA",
    "#9370DB",
  ];

  const handleAppointmentClick = (appointment) => {
    navigate('/chat', { state: { selectedUser: appointment.doctor } });
  };

  return (
    <div className="dashboard">
      <div className="dashboard-overlay">
        <MobileHeader toggleSidebar={toggleSidebar} />
        <div className="dashboard-box">
          <UserSidebar isOpen={isSidebarOpen} onClose={toggleSidebar} />
          <div
            className={`sidebar-overlay ${isSidebarOpen ? "active" : ""}`}
            onClick={() => setIsSidebarOpen(false)}
          ></div>

          <div className="dashboard-section">
            <div className="dashboard-left">
              <h1 className="username">
                Welcome, {first_name ? first_name : username}
              </h1>

              <div className="appointment-cards">
                {appointments && appointments.length > 0 ? (
                  appointments.map((appointment) => (
                    <div
                      key={appointment.id}
                      className="appointment-card"
                      onClick={() => handleAppointmentClick(appointment)}
                    >
                      <div className="doctor-info">
                        <img
                          src={
                            appointment.doctor &&
                            appointment.doctor.profile_photo
                              ? appointment.doctor.profile_photo
                              : defaultProfilePhoto
                          }
                          alt={`Dr. ${
                            appointment.doctor && appointment.doctor.user
                              ? `${appointment.doctor.user.first_name || "-"} ${appointment.doctor.user.last_name || "-"}`
                              : "-"
                          }`}
                          className="doctor-image"
                        />
                        <div className="doctor-details">
                          <h2>
                            {appointment.doctor && appointment.doctor.user
                              ? `Dr. ${appointment.doctor.user.first_name || "-"} ${appointment.doctor.user.last_name || "-"}`
                              : "Doctor Unassigned"}
                          </h2>
                          <p className="speciality">
                            {appointment.doctor
                              ? `${appointment.doctor.degree || "-"}, ${appointment.doctor.country || "-"}`
                              : "-"}
                          </p>
                        </div>
                      </div>
                      <div className="doctor-info-2">
                        <p className="branch">
                          {appointment.doctor
                            ? appointment.doctor.branch || "-"
                            : "-"}
                        </p>
                        <p className="specialization">
                          {appointment.doctor
                            ? appointment.doctor.specialization || "-"
                            : "-"}
                        </p>
                      </div>
                      <div className="datetime-info">
                        <div className="datetime-pair">
                          <span className="datetime-key">Date</span>
                          <span className="datetime-value">
                            {appointment.appointment_time
                              ? new Date(
                                  appointment.appointment_time
                                ).toLocaleDateString()
                              : "-"}
                          </span>
                        </div>
                        <div className="datetime-pair">
                          <span className="datetime-key">Time</span>
                          <span className="datetime-value">
                            {appointment.appointment_time
                              ? new Date(
                                  appointment.appointment_time
                                ).toLocaleTimeString()
                              : "-"}
                          </span>
                        </div>
                      </div>

                      <div className="appointment-actions">
                        <span>{appointment.status || "-"}</span>
                        <div className="appointment-actions-buttons">
                          <button>
                            <img src="/static/icons/call.png" alt="Call" />
                          </button>
                          <button>
                            <img
                              src="/static/icons/video.png"
                              alt="Video Call"
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="appointment-card">
                    <h4 className="not-found-message">
                      No appointments found.
                    </h4>
                  </div>
                )}
              </div>
            </div>

            <div className="dashboard-right">
              <h2>Make An Appointment, That Will Help You!</h2>

              <div className="dwhs">
                <div className="dwhs-container">
                  <div className="dwhs-dateCard">
                    <div className="dwhs-day">{day}</div>
                    <div className="dwhs-month">{month}</div>
                  </div>

                  <div className="actions-dwhs">
                    <div className="dwhs-infoContainer">
                      <div className="dwhs-infoContainer-box">
                        <div className="dwhs-infoCard">
                          <div className="dwhs-label">Book Now</div>

                          <div className="dwhs-value dwhs-weightValue">
                            Our executives will contact you within 24 hrs
                          </div>
                        </div>
                        <div className="dwhs-infoCard-img">
                          <img
                            src="static/icons/call-center-service.png"
                            alt=""
                            className="weight"
                          />
                        </div>
                      </div>

                      <div className="dwhs-infoContainer-box">
                        <div className="dwhs-infoCard">
                          <div className="dwhs-label">Your Quote</div>

                          <div className="dwhs-value dwhs-heightValue">
                            Your personalized quote will reach you soon
                          </div>
                        </div>
                        <div className="dwhs-infoCard-img">
                          <img
                            src="static/icons/left.png"
                            alt=""
                            className="height"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="actions">
                      <button
                        className="book-appointment-button"
                        onClick={() => setIsModalOpen(true)}
                      > <i class="fa-regular fa-calendar-check"></i>
                        Book Appointment
                      </button>
                      <button
                        className="free-quote-button"
                        onClick={() => setIsQuoteModalOpen(true)}
                      ><i class="fa-solid fa-quote-right"></i>
                        Free Quote
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="health-metrics-container">
                <div className="health-metrics-header">
                  <h2>Your Vitals</h2>
                  <button className="health-metrics-see-all" onClick={() => setIsVitalsModalOpen(true)}>Update</button>
                </div>

                <div className="health-metrics-grid">
                  {metrics.map((metric, index) => (
                    <div key={index} className="health-metrics-card">
                      <div className="health-metrics-info">
                        <span className="health-metrics-title">
                          {metric.title}
                        </span>
                        <span className="health-metrics-value">
                          {metric.value}
                        </span>
                        <span
                          className={`health-metrics-status ${metric.status.toLowerCase()}`}
                        >
                          {metric.status}
                        </span>
                      </div>
                      <div className="health-metrics-icon">{metric.icon}</div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="medical-history">
                <div className="history-header">
                  <h2>Medical History</h2>
                  <div className="history-actions">
                    <a href="#" className="see-all">
                      See All
                    </a>
                  </div>
                </div>
                <div className="history-content">
                  {medical_histories.length > 0 ? (
                    medical_histories.map((item, index) => (
                      <div className="history-item" key={index}>
                        <p className="history-date">
                          {formatDate(item.created_at)}
                        </p>
                        <h4 className="history-title">
                          {mapCategoryName(item.category)}
                        </h4>
                        <p className="history-description">
                          {item.description
                            ? item.description.length > 30
                              ? `${item.description.slice(0, 20)}...`
                              : item.description
                            : "-"}
                        </p>
                      </div>
                    ))
                  ) : (
                    <h4 className="not-found-message">
                      No medical history found.
                    </h4>
                  )}
                </div>
              </div>

              <div className="medical-reports">
                <div className="reports-header">
                  <h2>Medical Reports</h2>
                  <FaEllipsisV className="menu-icon" />
                </div>
                <div className="reports-content">
                  {medical_reports.length > 0 ? (
                    medical_reports.map((report, index) => {
                      const color = colors[index % colors.length];
                      const fileName = report.file
                        ? report.file.split("/").pop()
                        : "-";
                      return (
                        <a
                          key={index}
                          href={report.file || "#"}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="report-item"
                        >
                          <div className="report-details">
                            <div
                              className="report-icon"
                              style={{ color: color }}
                            >
                              <i className="fa-regular fa-file-lines"></i>
                            </div>
                            <p className="report-name">
                              {fileName.length > 20
                                ? `${fileName.slice(0, 20)}...`
                                : fileName}
                            </p>
                            <p className="report-date">
                              {formatDate(report.uploaded_at)}
                            </p>
                          </div>
                        </a>
                      );
                    })
                  ) : (
                    <h4 className="not-found-message">
                      No medical reports found.
                    </h4>
                  )}
                </div>
              </div>
            </div>

            <QuoteModal
              isOpen={isQuoteModalOpen}
              onClose={() => setIsQuoteModalOpen(false)}
            />

            <AppointmentModal
              isOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
            />
            <VitalsModal
              isOpen={isVitalsModalOpen}
              onClose={() => setIsVitalsModalOpen(false)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
