import React, { useState, useEffect } from 'react';
import { ChevronDown } from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDoctors, adminLogout } from '../slices/adminSlice';
import { useNavigate } from 'react-router-dom';
import AdminNavbar from '../Components/AdminNavbar';
import '../CSS/AdminDashboard.css';

const AdminDoctorDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const doctors = useSelector((state) => state.admin.doctors);
  const doctorStatus = useSelector((state) => state.admin.doctorStatus);
  const error = useSelector((state) => state.admin.doctorError);
  const adminToken = useSelector((state) => state.admin.adminToken);

  const [selectedBranch, setSelectedBranch] = useState('');

  useEffect(() => {
    if (!adminToken) {
      navigate('/admin/login');
    }
  }, [adminToken, navigate]);

  useEffect(() => {
    if (doctorStatus === 'idle') {
      dispatch(fetchDoctors());
    }
  }, [doctorStatus, dispatch]);

  const handleLogout = () => {
    dispatch(adminLogout());
    navigate('/admin/login');
  };

  const branches =
    doctors && doctors.length > 0
      ? Array.from(new Set(doctors.map((doctor) => doctor.branch)))
      : [];

  const filteredDoctors = selectedBranch
    ? doctors.filter((doctor) => doctor.branch === selectedBranch)
    : doctors;

  const sortedDoctors = [...filteredDoctors].sort((a, b) => {
    if (a.branch < b.branch) return -1;
    if (a.branch > b.branch) return 1;
    return 0;
  });

  return (
    <div className="admin-dashboard-container admin-doctor-container">
      <AdminNavbar />
      <div className="admin-dashboard-content">
        <div className="appointment-dashboard-header">
          <h2>Doctors</h2>
          <div className="doctor-filter">
            <label htmlFor="branch-filter">Filter by Branch:</label>
            <select
              id="branch-filter"
              value={selectedBranch}
              onChange={(e) => setSelectedBranch(e.target.value)}
              className="doctor-filter-select"
            >
              <option value="">All Branches</option>
              {branches.map((branch) => (
                <option key={branch} value={branch}>
                  {branch}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="appointment-dashboard-appointments-list">
          <div className="doctor-dashboard-header">
            <div>ID</div>
            <div>Profile Photo</div>
            <div>Name</div>
            <div>Specialization</div>
            <div>Email</div>
            <div>Branch</div>
            <div>Country</div>
            <div>Degree</div>
            <div>Phone</div>
            <div>Years of Experience</div>
            <div>Details</div>
          </div>

          {doctorStatus === 'loading' && <p>Loading doctors...</p>}
          {doctorStatus === 'failed' && <p>Error: {error}</p>}
          {doctorStatus === 'succeeded' &&
            sortedDoctors.map((doctor) => (
              <React.Fragment key={doctor.id}>
                <div className="doctor-dashboard-item">
                  <div>{doctor.user.id}</div>
                  <div>
                    <img
                      src={doctor.profile_photo}
                      alt="Profile"
                      className="doctor-profile-photo"
                    />
                  </div>
                  <div>
                    {doctor.user.first_name} {doctor.user.last_name}
                  </div>
                  <div>{doctor.specialization}</div>
                  <div>{doctor.user.email}</div>
                  <div>{doctor.branch}</div>
                  <div>{doctor.country}</div>
                  <div>{doctor.degree}</div>
                  <div>{doctor.phone}</div>
                  <div>{doctor.years_of_experience}</div>
                  <button
                    className="appointment-dashboard-action-button"
                    onClick={() => {
                      // Handle showing doctor details
                    }}
                    aria-label="View Details"
                  >
                    <ChevronDown size={18} />
                  </button>
                </div>
                {/* Add details section if needed */}
              </React.Fragment>
            ))}
        </div>
      </div>
    </div>
  );
};

export default AdminDoctorDashboard;
